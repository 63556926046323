export default {
  title: 'Reports',
  form: {
    titleNew: 'Neuer Report',
    hints: {
      moneyReport: 'GT - relevant für Excel-Sheet - Geld-Transaktionen',
      contractReport: 'A - relevant für Excel-Sheet - Aufträge',
    },
    header: {
      Groupings: 'Gruppierungsoptionen',
      Filter: 'Filteroptionen',
    },
    fromDate: {
      label: 'Report-Beginn',
    },
    toDate: {
      label: 'Report-Ende',
    },
    isGroupedByLanguage: {
      label: 'Gruppiert nach Sprache (A)',
    },
    language: {
      label: 'Sprache (A)',
    },
    isGroupedByBusinessUnit: {
      label: 'Gruppiert nach Geschäftsstelle (GT/A)',
    },
    businessUnit: {
      label: 'Geschäftsstelle (GT/A)',
    },
    isGroupedByContractType: {
      label: 'Gruppiert nach Auftragstyp (A)',
    },
    contractType: {
      label: 'Auftragstyp (A)',
    },
    isGroupedByContractNo: {
      label: 'Gruppiert nach Auftragsnummer (A)',
    },
    contractNo: {
      label: 'Auftragsnummer (A)',
    },
    isGroupedByContractState: {
      label: 'Gruppiert nach Auftragsstatus (A)',
    },
    contractState: {
      label: 'Auftragsstatus (A)',
    },
    isGroupedByTranslator: {
      label: 'Gruppiert nach Dolmetscher (A)',
    },
    translator: {
      label: 'Dolmetscher (A)',
    },
    isGroupedByCustomer: {
      label: 'Gruppiert nach Kunde (A)',
    },
    customer: {
      label: 'Kunde (A)',
    },
    isGroupedByMoneyOrigin: {
      label: 'Gruppiert nach Herkunft (GT)',
    },
    reportMoneyOrigin: {
      label: 'Herkunft (GT)',
    },
    isGroupedByAssignmentState: {
      label: 'Gruppiert nach Zuweisungsstatus (GT)',
    },
    bankAccountTransactionAssignmentState: {
      label: 'Zuweisungsstatus (GT)',
    },
    isGroupedByFinancialDocumentType: {
      label: 'Gruppiert nach Dokumenttyp (GT)',
    },
    financialDocumentType: {
      label: 'Dokumenttyp (GT)',
    },
    isGroupedByInvoiceState: {
      label: 'Gruppiert nach Rechnungsstatus (A)',
    },
    invoiceState: {
      label: 'Rechnungsstatus (A)',
    },
    isGroupedByInvoicePaymentState: {
      label: 'Gruppiert nach Zahlungsstatus (A)',
    },
    invoicePaymentState: {
      label: 'Zahlungsstatus (A)',
    },
    isGroupedByOperationLocation: {
      label: 'Gruppiert nach Einsatzort (A)',
    },
    operationLocation: {
      label: 'Einsatzort enthält (A)',
    },
    reportTimeGroup: {
      label: 'Zeitliche Gruppierung (GT/A)',
    },
  },
  list: {
    delete: {
      title: 'Report löschen',
      message: 'Soll der Report {id} wirklich gelöscht werden?',
    },
    header: {
      reportState: 'Report-Status',
      fromDate: 'Report-Beginn',
      toDate: 'Report-Ende',
      createDate: 'Erstellt am',
      groupSummary: 'Gruppiert nach',
      filterSummary: 'Gefiltert nach',
    },
    groupSummary: {
      isGroupedByLanguage: 'Sprache',
      isGroupedByBusinessUnit: 'Geschäftstellen',
      isGroupedByContractType: 'Auftragstypen',
      isGroupedByContractNo: 'Auftragsnummern',
      isGroupedByContractState: 'Auftragsstatus',
      isGroupedByTranslator: 'Dolmetschern',
      isGroupedByCustomer: 'Kunden',
      isGroupedByMoneyOrigin: 'Herkunft',
      isGroupedByAssignmentState: 'Zuweisungsstatus',
      isGroupedByFinancialDocumentType: 'Dokumenttyp',
      isGroupedByInvoiceState: 'Rechnungsstatus',
      isGroupedByInvoicePaymentState: 'Zahlungsstatus',
      isGroupedByOperationLocation: 'Einsatzort',
      reportTimeGroup: {
        WEEK: 'Wochen',
        MONTH: 'Monaten',
        YEAR: 'Jahren',
      },
    },
    filteredBy: {
      language: {
        label: 'Sprache',
      },
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      contractType: {
        label: 'Auftragstyp',
      },
      contractNo: {
        label: 'Auftragsnummer',
      },
      contractState: {
        label: 'Auftragsstatus',
      },
      translator: {
        label: 'Dolmetscher',
      },
      customer: {
        label: 'Kunde',
      },
      reportMoneyOrigin: {
        label: 'Herkunft',
      },
      bankAccountTransactionAssignmentState: {
        label: 'Zuweisungsstatus',
      },
      financialDocumentType: {
        label: 'Dokumenttyp',
      },
      invoiceState: {
        label: 'Rechnungsstatus',
      },
      invoicePaymentState: {
        label: 'Zahlungsstatus',
      },
      operationLocation: {
        label: 'Einsatzort',
      },
    },
    filter: {
      reportState: {
        label: 'Report-Status',
      },
    },
  },
  enums: {
    ReportState: {
      CREATED: 'Report angefordert',
      CREATION_FAILED_DATA_COLLECT: 'Fehler bei Datensammlung',
      CREATION_FAILED_PDF_CREATION: 'Fehler bei Excel-Report-Erstellung',
      CREATION_FINISHED: 'Report erstellt',
    },
    ReportTimeGroup: {
      DAY: 'gruppiert nach Tagen',
      WEEK: 'gruppiert nach Wochen',
      MONTH: 'gruppiert nach Monaten',
      YEAR: 'gruppiert nach Jahren',
    },
    ReportMoneyOrigin: {
      BANK: 'Bank',
      CASH: 'Handkasse',
    },
    FinancialDocumentType: {
      CREDIT: 'Gutschrift',
      INVOICE: 'Rechnung',
      EXTERNAL_DOCUMENT: 'Externer Beleg',
    },
    ContractType: {
      DOCUMENT_TRANSLATION: 'Schriftliche Übersetzung',
      ON_SITE_TRANSLATION: 'Vorort-Dolmetschen',
      VIDEO_TRANSLATION: 'Video-Dolmetschen',
    },
    ContractState: {
      CREATED: 'Erstellt',
      TRANSLATOR_ASSIGNED: 'Dolmetscher zugewiesen',
      TRANSLATOR_CONFIRMED: 'Dolmetscher bestätigt',
      CONTRACT_FULFILLED: 'Abgeschlossen',
      CANCELED: 'Storniert',
    },
    InvoicePaymentState: {
      PAID: 'bezahlt',
      NOT_PAID: 'nicht bezahlt',
    },
  },
};
