import base from '../../locale/de';
import _ from 'lodash';

export default _.merge(_.cloneDeep(base), {
  title: 'Video-Dolmetschen',
  usageDialog: {
    usage: 'Kommentar',
    close: 'schließen',
  },
  warningDialog: {
    usage: 'Hinweis',
    close: 'schließen',
    creditAlreadyExists: 'Für die gewählten Aufträge existiert bereits mindestens eine Gutschrift.',
    createCreditForced: 'zusätzliche Gutschrift erstellen',
    invoiceAlreadyExists: 'Für die gewählten Aufträge existiert bereits mindestens eine Rechnung.',
    createInvoiceForced: 'zusätzliche Rechnung erstellen',
    hasMoreThanOneTranslator: 'Für die Gutschriftserstellung darf nur ein Dolmetscher ausgewählt sein. Bitte passen Sie Ihre Auftragsauswahl an.',
    hasMoreThanOneCustomer: 'Für die Rechnungserstellung darf nur ein Kunde ausgewählt sein. Bitte passen Sie Ihre Auftragsauswahl an.',
    isCreditAlreadyCreated: 'Es existiert bereits mindestens eine Gutschrift für die gewählten Aufträge.',
    isInvoiceAlreadyCreated: 'Es existiert bereits mindestens eine Rechnung für die gewählten Aufträge.',
  },
  enums: {
    CommunicationSystem: {
      JITSI: 'Jitsi',
      BRAVIS: 'BRAVIS',
    },
  },
  list: {
    header: {
      contractNo: 'Auftragsnummer',
      contractState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      translator: 'Dolmetscher',
      translatorLanguage: 'Sprache',
      contractDate: 'Auftragsdatum',
      contractComment: 'Kommentar',
    },
    filter: {
      contractNo: {
        label: 'Auftragsnummer',
      },
      contractState: {
        label: 'Status',
      },
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      customer: {
        label: 'Kunde',
      },
      translator: {
        label: 'Dolmetscher',
      },
      contractDateFrom: {
        label: 'Auftragsdatum ab',
      },
      contractDateUntil: {
        label: 'Auftragsdatum bis',
      },
      contractComment: {
        label: 'Kommentar enthält',
      },
      invoiceFilter: {
        label: 'Rechnung',
      },
      creditFilter: {
        label: 'Gutschrift',
      },
      language: {
        label: 'Sprache',
      },
    },
    edit: {
      contractState: {
        fulfill: 'Auftrag erfüllt',
        cancel: 'Auftrag annullieren',
        confirmCancel: {
          title: 'Auftrag annullieren',
          message: 'Soll der Auftrag {contractNo} jetzt annulliert werden?',
          yes: {
            label: 'Auftrag annullieren',
          },
        },
      },
      translator: {
        assign: 'zuweisen',
        confirm: 'Dolmetscher bestätigen',
        label: 'Dolmetscher auswählen',
      },
    },
    actions: {
      showInvoice: 'Rechnung anzeigen',
    },
    createInvoice: {
      label: 'Rechnung erstellen',
    },
  },
  form: {
    titleNew: 'Neuer Auftrag',
    beginDateTime: {
      label: 'geplanter Beginn',
    },
    plannedTranslationDuration: {
      label: 'Geplante Dauer in Min.',
    },
    communicationSystem: {
      label: 'Kommunikationssystem',
    },
    save: {
      success: 'Der Auftrag mit der Nummer {contractNo} wurde gespeichert.',
    },
    contractComment: {
      label: 'Kommentar',
    },
    afterFulfillmentText: {
      label: 'Erst nach Auftragsabschluss einzutragen.',
    },
  },
  customerCommentDialog: {
    content: 'Kommentar - Kunde',
    close: 'Schließen',
  },
  translatorCommentDialog: {
    content: 'Kommentar - Dolmetscher',
    close: 'Schließen',
  },
  invoiceContact: {
    title: 'Rechnungskontakt',
  },
  creditContact: {
    title: 'Kontakt für Gutschrift',
  },
  event: {
    details: {
      contractState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      translator: 'Dolmetscher',
      contractDate: 'Auftragsdatum',
    },
  },
});
