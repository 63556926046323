export default {
  title: 'Kalender',

  type: {
    'month': 'Monat',
    'week': 'Woche',
    'day': 'Tag',
    '4day': '4 Tage',
  },

  header: {
    'month': '{startMonth} {startYear}',
    'week': '{startDay} {startMonth} {startYear} - {endDay} {endMonth} {suffixYear}',
    'day': '{startDay} {startMonth} {startYear}',
    '4day': '{startDay} {startMonth} {startYear} - {endDay} {endMonth} {suffixYear}',
  },

  filter: {
    businessUnit: 'Geschäftstelle',
    language: 'Sprache',
    translator: 'Übersetzer',
    customer: 'Kunde',
    calendarObjectTypes: 'Eintrags-Typ',
  },

  today: {
    label: 'Heute',
  },

  navigate: {
    label: 'Öffnen',
  },

  appointmentCommentDialog: {
    content: 'Beschreibung des Eintrags',
    close: 'Schließen',
  },

  hourOverviewDialog: {
    title: 'Stundenübersicht',
    close: 'Schließen',
  },

  enums: {
    CalendarObjectTypes: {
      ON_SITE_CONTRACT: 'Aufträge - Vorort-Dolmetschen',
      DOCUMENT_CONTRACT: 'Aufträge - Schriftliche Übersetzung',
      VIDEO_CONTRACT: 'Aufträge - Video-Dolmetschen',
      INVOICE: 'Rechnungen',
      TASK: 'Aufgaben',
    },
  },
};
