export default {
  title: 'Kunden',
  list: {
    actions: {
      sendEmailToCustomer: 'Email an Kunden senden',
    },
    header: {
      name: 'Name',
      email: 'E-Mail',
      customerGroup: 'Kundengruppe',
      costCenter: 'Kostenstelle',
      customerComment: 'Kommentar',
    },
    filter: {
      firstName: {
        label: 'Vorname',
      },
      lastName: {
        label: 'Nachname',
      },
      email: {
        label: 'Email',
      },
      customer: {
        label: 'Kunde',
      },
      customerComment: {
        label: 'Kommentar enthält',
      },
      costCenter: {
        label: 'Kostenstelle',
      },
      customerGroup: {
        label: 'Kundengruppe',
      },
    },
    delete: {
      title: 'Kunde löschen',
      message: 'Soll der Benutzer {fullName} wirklich gelöscht werden?',
    },
    edit: {
      sendEmail: {
        title: 'Sende Email',
      },
    },
  },
  form: {
    titleNew: 'Neuer Kunde',
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
    taxId: {
      label: 'Steuer-ID',
    },
    vatExempt: {
      label: 'mehrwertsteuerbefreit',
    },
    customerGroup: {
      label: 'Kundengruppe',
    },
    costCenter: {
      label: 'Kostenstelle',
    },
    customerComment: {
      label: 'Kommentar',
    },
    isLocked: {
      label: 'Nutzer ist gesperrt',
    },
    sendActivationEmail: {
      label: 'sende Aktivierungs-Email',
    },
  },
  commentDialog: {
    comment: 'Kommentar',
    close: 'Schließen',
  },
  languageGroups: {
    title: 'Sprachgruppen',
    noData: 'Keine Sprachgruppen definiert',
    name: {
      label: 'Bezeichnung',
      error: {
        customerLanguageGroupsNameDuplicates: 'Bezeichnungen der Sprachgruppen müssen eindeutig sein.',
      },
    },
    languages: {
      label: 'Sprachen',
      error: {
        customerLanguageGroupsLanguagesDuplicates: 'Eine Sprache darf nicht mehreren Sprachgruppen zugeordnet sein.',
      },
    },
  },
  costConfiguration: {
    title: 'Kostenkonfiguration',
    fixedRateNet: {
      label: 'Pauschale (netto)',
    },
    costConfigurationCycles: {
      label: 'Abrechnungszyklen',
      titleUnknown: 'Neuer Abrechnungszyklus',
      noData: 'Keine Abrechnungszyklen definiert',
      customerCostConfigurationCycleDuplicates: 'Achtung: Kombination aus Sprachgruppe und Abrechnungseinheit ist nicht eindeutig.',
      costConfigurationCycle: {
        customerLanguageGroup: {
          label: 'Sprachgruppe',
        },
        costConfigurationCycleType: {
          label: 'Abrechnungseinheit',
        },
        firstCycleDuration: {
          label: 'Erste Taktung',
        },
        firstCycleCostNet: {
          label: 'Kosten für erste Taktung (netto)',
        },
        followingCycleDuration: {
          label: 'Weitere Taktung',
        },
        followingCycleCostNet: {
          label: 'Kosten pro weiterer Taktung (netto)',
        },
      },
    },
  },
  invoiceContact: {
    title: 'Rechnungskontakt',
  },
  email: {
    title: 'E-Mail senden',
    to: {
      label: 'An',
    },
    cc: {
      label: 'Kopie an',
    },
    subject: {
      label: 'Betreff',
    },
    body: {
      label: 'Inhalt',
    },
    send: {
      label: 'Senden',
    },
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
};
