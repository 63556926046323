export default {
  title: 'Benutzerkonto',
  account: {
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
  },
  changePassword: {
    title: 'Passwort',
    passwordOld: {
      label: 'Aktuelles Passwort',
    },
    passwordNew: {
      label: 'Neues Passwort',
    },
    passwordNewConfirm: {
      label: 'Neues Passwort wiederholen',
    },
    success: 'Ihr Passwort wurde geändert.',
  },
  contact: {
    title: 'Kontaktinformationen',
  },
  enums: {
    UserType: {
      CUSTOMER: 'Kunde',
      TRANSLATOR: 'Dolmetscher',
      OFFICE_WORKER: 'Sachbearbeiter',
      ADMIN: 'Admin',
      ACCOUNTANCY_FIRM: 'Steuerbüro',
      UNKNOWN: 'unbekannte Rolle',
    },
    UserPermission: {
      APPOINTMENT_READ: 'Termine lesen',
      APPOINTMENT_WRITE: 'Termine schreiben',
      CONTRACT_READ: 'Aufträge lesen',
      CONTRACT_WRITE: 'Aufträge schreiben',
      ACCOUNTING_READ: 'Buchhaltung lesen',
      ACCOUNTING_WRITE: 'Buchhaltung schreiben',
      ACCOUNTING_DELETE: 'Buchhaltung löschen',
      MASTER_DATA_READ: 'Stammdaten lesen',
      MASTER_DATA_WRITE: 'Stammdaten schreiben',
      LANGUAGE_READ: 'Sprachen lesen',
      LANGUAGE_WRITE: 'Sprachen schreiben',
      EMAIL_TEMPLATE_READ: 'E-Mail-Vorlagen lesen',
      EMAIL_TEMPLATE_WRITE: 'E-Mail-Vorlagen schreiben',
      TRANSLATOR_READ: 'Dolmetscher lesen',
      TRANSLATOR_WRITE: 'Dolmetscher schreiben',
      CUSTOMER_READ: 'Kunden lesen',
      CUSTOMER_WRITE: 'Kunden schreiben',
      REPORT_READ: 'Reports lesen',
      REPORT_WRITE: 'Reports schreiben',
    },
  },
};
