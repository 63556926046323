export default {
  title: 'Geschäftsstellen',
  list: {
    header: {
      name: 'Geschäftsstelle',
    },
    delete: {
      title: 'Geschäftsstelle löschen',
      message: 'Soll die Geschäftsstelle {id} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neue Geschäftsstelle',
    name: {
      label: 'Bezeichnung',
    },
    invoiceNoPrefixOnSiteContract: {
      label: 'Rechnungs-Nr.-Präfix: Vorort-Auftrag',
    },
    invoiceNoPrefixDocumentContract: {
      label: 'Rechnungs-Nr.-Präfix: Schriftliche Übersetzung',
    },
    invoiceNoPrefixVideoTranslationContract: {
      label: 'Rechnungs-Nr.-Präfix: Videodolmetschen',
    },
    creditNoPrefixOnSiteContract: {
      label: 'Gutschrifts-Nr.-Präfix: Vorort-Auftrag',
    },
    creditNoPrefixDocumentContract: {
      label: 'Gutschrifts-Nr.-Präfix: Schriftliche Übersetzung',
    },
    creditNoPrefixVideoTranslationContract: {
      label: 'Gutschrifts-Nr.-Präfix: Videodolmetschen',
    },
  },
  invoiceContact: {
    title: 'Rechnungskontakt',
  },
  bankAccountCollection: {
    title: 'Bankverbindungen',
  },
  bankaccountcollectionform: {
    title: 'Bankverbindungen',
    noData: 'Keine Bankverbindungen hinterlegt',
    bankName: {
      label: 'Name der Bank',
    },
    IBAN: {
      label: 'IBAN',
    },
    BIC: {
      label: 'BIC',
    },
    isActive: {
      label: 'Ist die Bankverbindung aktiv?',
    },
  },
};
