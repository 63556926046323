export default {
  dialog: {
    setStateAdmin: {
      title: 'Status setzen - ADMIN-AKTION!',
      text: 'Bitte wählen sie den gewünschten Status.',
      close: 'Schließen',
    },
  },
  enums: {
    ReceiverOption: {
      CONTACT_ADDRESS: 'Kontaktadresse',
      COST_CENTER: 'Kostenstelle',
      ALTERNATIVE_ADDRESS: 'Benutzerdefinierte Adresse',
    },
    ContractState: {
      CREATED: 'Erstellt',
      TRANSLATOR_ASSIGNED: 'Dolmetscher zugewiesen',
      TRANSLATOR_CONFIRMED: 'Dolmetscher bestätigt',
      CONTRACT_FULFILLED: 'Abgeschlossen',
      CANCELED: 'Storniert',
    },
    InvoiceFilter: {
      INVOICE_CREATED: 'Rechnung erstellt',
      INVOICE_NOT_CREATED: 'Rechnung nicht erstellt',
    },
    CreditFilter: {
      CREDIT_CREATED: 'Gutschrift erstellt',
      CREDIT_NOT_CREATED: 'Gutschrift nicht erstellt',
    },
  },
  list: {
    setStateAdmin: {
      label: 'Status setzen (ADMIN)',
    },
  },
  form: {
    businessUnit: {
      label: 'Geschäftsstelle',
    },
    customer: {
      label: 'Kunde',
    },
    contractDate: {
      label: 'Auftragsdatum',
    },
    customerLanguage: {
      label: 'Ausgangssprache',
    },
    translatorLanguage: {
      label: 'Zielsprache',
    },
    invoiceReceiverOption: {
      label: 'Rechnungsadresse des Kunden',
    },
    creditReceiverOption: {
      label: 'Gutschriftadresse des Dolmetschers',
    },
    afterFulfillmentText: {
      label: 'Erst nach Auftragsabschluss einzutragen.',
    },
  },
};
