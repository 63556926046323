export default {
  enums: {
    EmailType: {
      PRIVATE: 'privat',
      BUSINESS: 'geschäftlich',
      OTHER: 'andere',
    },
    PhoneNumberType: {
      PRIVATE: 'privat',
      BUSINESS: 'geschäftlich',
      PRIVATE_MOBILE: 'privat mobil',
      BUSINESS_MOBILE: 'geschäftlich mobil',
      FAX: 'Fax',
      OTHER: 'andere',
    },
  },
  contactform: {
    company: {
      label: 'Firma',
    },
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    street: {
      label: 'Straße und Hausnummer',
    },
    zipCode: {
      label: 'Postleitzahl',
    },
    city: {
      label: 'Ort',
    },
    country: {
      label: 'Land',
    },
    emails: {
      title: 'E-Mail-Adressen',
      itemTitleUnknown: 'Neue E-Mail-Adresse',
      noData: 'Keine E-Mail-Adressen hinterlegt',
      emailType: {
        label: 'Art',
      },
      email: {
        label: 'E-Mail-Adresse',
      },
    },
    phoneNumbers: {
      title: 'Telefonnummern',
      itemTitleUnknown: 'Neue Telefonnummer',
      noData: 'Keine Telefonnummern hinterlegt',
      phoneNumberType: {
        label: 'Art',
      },
      phoneNumber: {
        label: 'Nummer',
      },
    },
  },
};
