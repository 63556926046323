import base from '../../locale/de';
import _ from 'lodash';

export default _.merge(_.cloneDeep(base), {
  title: 'Schriftliche Übersetzungen',
  usageDialog: {
    usage: 'Kommentar',
    close: 'schließen',
  },
  warningDialog: {
    usage: 'Hinweis',
    close: 'schließen',
    creditAlreadyExists: 'Für die gewählten Aufträge existiert bereits mindestens eine Gutschrift.',
    createCreditForced: 'zusätzliche Gutschrift erstellen',
    invoiceAlreadyExists: 'Für die gewählten Aufträge existiert bereits mindestens eine Rechnung.',
    createInvoiceForced: 'zusätzliche Rechnung erstellen',
    hasMoreThanOneTranslator: 'Für die Gutschriftserstellung darf nur ein Dolmetscher ausgewählt sein. Bitte passen Sie Ihre Auftragsauswahl an.',
    hasMoreThanOneCustomer: 'Für die Rechnungserstellung darf nur ein Kunde ausgewählt sein. Bitte passen Sie Ihre Auftragsauswahl an.',
    isCreditAlreadyCreated: 'Es existiert bereits mindestens eine Gutschrift für die gewählten Aufträge.',
    isInvoiceAlreadyCreated: 'Es existiert bereits mindestens eine Rechnung für die gewählten Aufträge.',
  },
  list: {
    header: {
      contractNo: 'Auftragsnummer',
      contractState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      translator: 'Dolmetscher',
      translatorLanguage: 'Sprache',
      contractDate: 'Auftragsdatum',
      contractComment: 'Kommentar',
    },
    filter: {
      contractNo: {
        label: 'Auftragsnummer',
      },
      contractState: {
        label: 'Status',
      },
      contractComment: {
        label: 'Kommentar enthält',
      },
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      customer: {
        label: 'Kunde',
      },
      translator: {
        label: 'Dolmetscher',
      },
      contractDateFrom: {
        label: 'Auftragsdatum ab',
      },
      contractDateUntil: {
        label: 'Auftragsdatum bis',
      },
      invoiceFilter: {
        label: 'Rechnung-Erstellung',
      },
      creditFilter: {
        label: 'Gutschrift-Erstellung',
      },
      language: {
        label: 'Sprache',
      },
      invoiceId: {
        label: 'Rechnung',
      },
      creditId: {
        label: 'Gutschrift',
      },
    },
    invoiceCreated: 'Rechnung erstellt',
    creditCreated: 'Gutschrift erstellt',
    invoiceAndCreditCreated: 'Rechnung & Gutschrift erstellt',
    edit: {
      contractState: {
        fulfill: 'Auftrag erfüllt',
        cancel: 'Auftrag stornieren',
        confirmCancel: {
          title: 'Auftrag stornieren',
          message: 'Soll der Auftrag {contractNo} jetzt stornieren werden?',
          yes: {
            label: 'Auftrag stornieren',
          },
        },
      },
      translator: {
        assign: 'zuweisen',
        confirm: 'Dolmetscher bestätigen',
        label: 'Dolmetscher auswählen',
      },
      sendEmail: {
        title: 'E-Mail-Versand',
      },
    },
    actions: {
      showInvoice: 'Rechnung anzeigen',
      showCredit: 'Gutschrift anzeigen',
      sendEmailToTranslator: 'E-Mail-Versand an Dolmetscher',
      sendEmailToCustomer: 'E-Mail-Versand an Kunden',
    },
    createInvoice: {
      label: 'Rechnung erstellen',
    },
    createCredit: {
      label: 'Gutschrift erstellen',
    },
  },
  form: {
    titleNew: 'Neuer Auftrag',
    completeUntil: {
      label: 'Zu übersetzen bis',
    },
    linesOfOriginalFile: {
      label: 'Anzahl der Zeilen des Originaldokuments',
    },
    linesOfTranslatedFile: {
      label: 'Anzahl der Zeilen der Übersetzung',
    },
    save: {
      success: 'Der Auftrag mit der Nummer {contractNo} wurde gespeichert.',
    },
    contractComment: {
      label: 'Kommentar',
    },
    createCustomer: {
      label: 'Kundenneuanlage',
    },
  },
  customerCommentDialog: {
    content: 'Kommentar - Kunde',
    close: 'Schließen',
  },
  translatorCommentDialog: {
    content: 'Kommentar - Dolmetscher',
    close: 'Schließen',
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
  fileAttachmentCollectionOriginal: {
    title: 'Original Dokumente',
  },
  fileAttachmentCollectionTranslated: {
    title: 'Übersetzte Dokumente',
  },
  invoiceContact: {
    title: 'Rechnungskontakt',
  },
  creditContact: {
    title: 'Kontakt für Gutschrift',
  },
  email: {
    title: 'E-Mail senden',
    to: {
      label: 'An',
    },
    cc: {
      label: 'Kopie an',
    },
    subject: {
      label: 'Betreff',
    },
    body: {
      label: 'Inhalt',
    },
    send: {
      label: 'Senden',
    },
  },
  event: {
    details: {
      contractState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      translator: 'Dolmetscher',
      contractDate: 'Auftragsdatum',
      completeUntil: 'Zu übersetzen bis',
    },
  },
});
