export default {
  title: 'Systemeinstellungen',
  list: {
    header: {
      key: 'Eigenschaft',
    },
  },
  form: {
    value: {
      label: '',
    },
  },
  keys: {
    INITIAL_LANGUAGE_GROUPS: 'Initiale Sprachgruppen',
    REPORT_DISTRICT_COURT: 'Report: Amtsgericht',
    REPORT_TAX_NUMBER: 'Report: Steuernummer',
    REPORT_TAX_OFFICE: 'Report: Finanzamt',
    REPORT_HOMEPAGE_URL: 'Report: Homepage',
    REPORT_HEADQUARTER: 'Report: Hauptsitz',
    REPORT_MANAGER: 'Report: Geschäftsführer',
    REPORT_HOTLINE_24H: 'Report: 24-Stunden-Hotline',
    EMAIL_SEND_ENABLED: 'Email-Versand aktiviert',
    EMAIL_SEND_ENABLED_FOR_AUTOMATIC_ACTIVATION: 'Automatischer Versand einer Aktivierungsmail bei Anlage eines Nutzers',
    EMAIL_ADDRESS: 'System-Email: Email-Adresse',
    EMAIL_USER_NAME:  'System-Email: Nutzer-Kennung',
    EMAIL_PASSWORD: 'System-Email: Passwort',
    EMAIL_SERVER_HOST: 'System-Email: Host',
    EMAIL_SMTP_PORT: 'System-Email: Port',
    PDF_LOGO: 'PDF-Logo',
    SALES_TAX_ID: 'Umsatzsteuer-ID',
    WEBDAV_URL: 'WebDAV-Server URL',
    WEBDAV_USERNAME: 'WebDAV-Server Nutzername',
    WEBDAV_PASSWORD: 'WebDAV-Server Passwort',
    BASE_URL: 'Basis-URL des Systems',
    DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS: 'Standard Ausgangssprache für Aufträge',
  },
};
