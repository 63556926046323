export default {
  title: 'Kostenstellen',
  list: {
    header: {
      name: 'Kostenstelle',
    },
    delete: {
      title: 'Kostenstelle löschen',
      message: 'Soll die Kostenstelle {name} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neue Kostenstelle',
    name: {
      label: 'Bezeichnung',
    },
  },
  invoiceContact: {
    title: 'Rechnungskontakt',
  },
};
