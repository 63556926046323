export default {
  title: 'Kundengruppen',
  list: {
    header: {
      name: 'Bezeichnung',
    },
    delete: {
      title: 'Kundegruppe löschen',
      message: 'Soll die Kundengruppe {name} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neue Kundengruppe',
    name: {
      label: 'Bezeichnung',
    },
  },
};
