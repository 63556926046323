export default {
  title: 'Aufgaben',
  enums: {
    TaskState: {
      CREATED: 'erstellt',
      DONE: 'erledigt',
    },
  },
  list: {
    delete: {
      title: 'Aufgabe löschen',
      message: 'Soll die Aufgabe wirklich gelöscht werden?',
    },
    filter: {
      taskName: { label: 'Name enthält:' },
      taskState: { label: 'Status ist:' },
      taskDescription: { label: 'Beschreibung enthält:' },
      taskDateFrom: { label: 'Fällig ab:' },
      taskDateTo: { label: 'Fällig bis:' },
      assignedAdmin: { label: 'zugewiesener Administrator:' },
      assignedOfficeWorker: { label: 'zugewiesener Sachbearbeiter:' },
    },
    header: {
      taskName: 'Name',
      taskState: 'Status',
      taskDescription: 'Beschreibung',
      taskDate: 'Fällig bis',
      createdBy: 'Ersteller',
      assignedAdmins: 'zugewiesene Administratoren',
      assignedOfficeWorkers: 'zugewiesene Sachbearbeiter',
    },
    edit: {
      fulfill: 'Setze auf: Erledigt',
      cancel: 'Setze auf: Erstellt',
    },
  },
  form: {
    taskName: { label: 'Name' },
    taskDescription: { label: 'Beschreibung' },
    taskDate: { label: 'Fälligkeitsdatum:' },
    titleNew: 'Neue Aufgabe',
    createdBy: { label: 'Ersteller: {0}' },
    assignedAdmins: { label: 'zugewiesene Administratoren:' },
    assignedOfficeWorkers: { label: 'zugewiesene Sachbearbeiter:' },
  },
  usageDialog: {
    usage: 'Beschreibung',
    close: 'schließen',
  },
  event: {
    details: {
      taskState: 'Aufgabenstatus',
      taskDate: 'Fälligkeitsdatum',
      taskName: 'Name',
    },
  },
};
