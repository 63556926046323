export default {
  title: 'Kontoumsätze',
  usageDialog: {
    usage: 'Verwendungszweck',
    close: 'schließen',
  },
  list: {
    header: {
      btIdentity: 'Transaktions-ID',
      btValue: 'Umsatz',
      btValueAssigned: 'Beleg-Summe',
      amountOfBalance: 'Saldo',
      btUsage: 'Verwendungszweck',
      transactionDate: 'Buchungstag',
      businessUnit: 'Geschäftsstelle',
      bankAccount: 'Kontoverbindung',
      bankAccountTransactionAssignmentState: 'Zuweisungsstatus',
    },
    edit: {
      acceptIncompleteAssignment: 'akzeptiere unvollständige Zuweisung',
    },
    filter: {
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      bankAccount: {
        label: 'Kontoverbindung enthält',
      },
      btValueFrom: {
        label: 'Umsatz größer als',
      },
      btValueTo: {
        label: 'Umsatz kleiner als',
      },
      BankAccountTransactionAssignmentState: {
        label: 'Zuweisungsstatus',
      },
      btUsage: {
        label: 'Verwendungszweck enthält',
      },
      btIdentity: {
        label: 'Transaktions-ID',
      },
      transactionDateFrom: {
        label: 'Transaktionsdatum ab',
      },
      transactionDateUntil: {
        label: 'Transaktionsdatum bis',
      },
      invoiceId: {
        label: 'Rechnung',
      },
      creditId: {
        label: 'Gutschrift',
      },
      externalDocumentId: {
        label: 'Externer Beleg',
      },
    },
  },
  form: {
    btValue: {
      label: 'Umsatz',
    },
    btUsage: {
      label: 'Verwendungszweck',
    },
    transactionDate: {
      label: 'Buchungstag',
    },
    businessUnit: {
      label: 'Geschäftsstelle',
    },
    bankAccountTransactionInvoicePositions: {
      label: 'Zuweisungen - Rechnungen',
    },
    bankAccountTransactionCreditPositions: {
      label: 'Zuweisungen - Gutschriften',
    },
    bankAccountTransactionExternalDocumentPositions: {
      label: 'Zuweisungen - Externe Belege',
    },
    notAllBusinessUnitAssignmentsValid: 'Achtung: nicht alle zugewiesenen Belege gehören zum ausgewählten Konto der Geschäftstelle {0}.',
    createExternalDocument: {
      label: 'Neuanlage Externer Beleg',
    },
  },
  enums: {
    BankAccountTransactionAssignmentState: {
      NOT_ASSIGNED: 'nicht zugewiesen',
      ASSIGNMENT_INCOMPLETE_AUTOMATIC: 'unvollständig (automatisch)',
      ASSIGNMENT_INCOMPLETE_MANUAL: 'unvollständig',
      ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED: 'unvollständig, aber akzeptiert',
      ASSIGNMENT_COMPLETE_AUTOMATIC: 'vollständig (automatisch)',
      ASSIGNMENT_COMPLETE_MANUAL: 'vollständig',
    },
  },
  positionList: {
    header: {
      invoice: 'Rechnung',
      credit: 'Gutschrift',
      externalDocument: 'Externer Beleg',
      businessUnit: 'Geschäftsstelle',
      assignedPriceNet: 'Belegwert (netto)',
      assignedPriceGross: 'Belegwert (brutto)',
    },
    edit: {
      addBankTransactionPosition: {
        label: 'Neue Zuweisung',
      },
      assignedPriceNet: {
        label: 'Belegwert (netto)',
      },
      assignedPriceGross: {
        label: 'Belegwert (brutto)',
      },
    },
  },
};
