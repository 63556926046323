export default {
  title: 'Administratoren',
  list: {
    header: {
      name: 'Name',
      email: 'E-Mail',
    },
    delete: {
      title: 'Administrator löschen',
      message: 'Soll der Benutzer {fullName} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neuer Administrator',
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
    isLocked: {
      label: 'Nutzer ist gesperrt',
    },
    sendActivationEmail: {
      label: 'sende Aktivierungs-Email',
    },
  },
};
