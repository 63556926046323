export default {
  title: 'Sprachen',
  list: {
    header: {
      isoCode: 'ISO-Code',
      name: 'Bezeichnung',
    },
    delete: {
      title: 'Sprache löschen',
      message: 'Soll die Sprache {name} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neue Sprache',
    isoCode: {
      label: 'ISO-Code',
      hint: 'Code nach <a href="https://de.wikipedia.org/wiki/Liste_der_ISO-639-1-Codes" target="_blank">ISO-639-2/T</a>',
    },
    name: {
      label: 'Bezeichnung',
    },
  },
};
