export default {
  title: 'Rechnungen',
  download: 'Download - {0}',
  dialog: {
    cancelInvoice: {
      title: 'Rechnungen stornieren',
      text: 'Möchten Sie die Rechnungen wirklich stornieren?',
      cancel: 'Stornieren',
      close: 'Schließen',
    },
    setStateAdmin: {
      title: 'Status setzen - ADMIN-AKTION!',
      text: 'Bitte wählen Sie den gewünschten Status.',
      close: 'Schließen',
    },
    showDownloadPdfZipCheck: {
      title: 'Hinweis zum Sammel-Download',
      text: 'Für folgende Rechnungen wurden keine PDF gefunden:',
      downloadForced: 'Alle anderen downloaden',
      close: 'Schließen',
    },
    warning: {
      title: 'Warnung!',
      invoiceNotApproved: 'Rechnungen müssen bestätigt sein, damit sie storniert werden können.',
      close: 'Schließen',
    },
  },
  usageDialog: {
    usage: 'Kommentar',
    close: 'schließen',
  },
  message: {
    reversalInvoiceCreated: 'Stornorechnung erstellt.',
  },
  enums: {
    InvoiceState: {
      CREATED: 'erstellt',
      APPROVED: 'bestätigt',
      CANCELED: 'storniert',
    },
    Vat: {
      ZERO: 'ohne',
      REDUCED: 'reduziert',
      FULL: 'voll',
    },
    InvoiceType: {
      DOCUMENT_TRANSLATION: 'Schriftliche Übersetzung',
      ON_SITE_TRANSLATION: 'Vorort-Dolmetschen',
      VIDEO_TRANSLATION: 'Video-Dolmetschen',
    },
  },
  list: {
    reversalTo: 'Storno zu',
    cancelInvoice: {
      label: 'Rechnung stornieren',
    },
    downloadAccumulativePDF: {
      label: 'Download-PDF (ZIP)',
    },
    setStateAdmin: {
      label: 'Status setzen (ADMIN)',
    },
    actions: {
      sendEmailToCustomer: 'Email an Kunden senden',
    },
    header: {
      invoiceReference: 'Rechnungsnummer',
      invoiceState: 'Status',
      invoiceDate: 'Rechnungsdatum',
      customer: 'Kunde',
      businessUnit: 'Geschäftsstelle',
      invoiceComment: 'Kommentar',
      isPaymentCompleted: 'Bezahlung erfolgt',
      downloadPDF: 'PDF',
    },
    delete: {
      title: 'Rechnung löschen',
      message: 'Soll die Rechnung wirklich gelöscht werden?',
    },
    filter: {
      invoiceReference: {
        label: 'Rechnungsnummer',
      },
      invoiceState: {
        label: 'Status',
      },
      invoiceComment: {
        label: 'Kommentar',
      },
      contractNo: {
        label: 'Auftragsnummer',
      },
      customer: {
        label: 'Kunde',
      },
      translator: {
        label: 'Dolmetscher',
      },
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      invoiceDateFrom: {
        label: 'Rechnungsdatum ab',
      },
      invoiceDateUntil: {
        label: 'Rechnungsdatum bis',
      },
      isPaymentCompleted: {
        label: 'Bezahlung erfolgt',
        true: 'Ja',
        false: 'Nein',
      },
      invoiceType: {
        label: 'Rechnungstyp',
      },
      pdfCreatorAdmin: {
        label: 'Rechnungs-PDF-Ersteller (Admin)',
      },
      pdfCreatorOfficeWorker: {
        label: 'Rechnungs-PDF-Ersteller (Sachbearbeiter)',
      },
      isCreatedPDF: {
        label: 'Rechnungs-PDF erstellt',
        true: 'Ja',
        false: 'Nein',
      },
    },
    edit: {
      fulfill: 'Rechnung abschließen / bestätigen ',
      sendEmail: {
        title: 'Sende Email',
      },
    },
  },
  form: {
    headerInfo: {
      label: 'Rechnungsinformationen',
    },
    senderContact: {
      label: 'Absender',
    },
    receiverContact: {
      label: 'Empfänger',
    },
    invoicePositions: {
      label: 'Positionen',
    },
    createPDF: {
      label: 'PDF erstellen',
    },
    invoiceFiles: {
      label: 'Rechnungsdownload',
    },
    invoiceDate: {
      label: 'Rechnungsdatum',
    },
    invoiceReference: {
      label: 'Rechnungsnummer',
    },
    operationDate: {
      label: 'Einsatzdatum',
    },
    operationTime: {
      label: 'Einsatzzeit',
    },
    operationLocation: {
      label: 'Einsatzort',
    },
    language: {
      label: 'Sprache',
    },
    customerAsText: {
      label: 'Auftraggeber',
    },
    translatorAsText: {
      label: 'Dolmetscher',
    },
    reference: {
      label: 'Referenz',
    },
    notice: {
      label: 'Bemerkung',
    },
    invoiceComment: {
      label: 'Kommentar',
    },
    explanation: {
      label: 'Erläuterung',
    },
    isPaymentCompleted: {
      label: 'Bezahlung erfolgt',
    },
    fileCreatedBy: {
      label: 'Ersteller: {0}',
    },
  },
  invoiceSummary: {
    header: 'Zusammenfassung',
    subtotal: 'Zwischensumme',
    vat: 'zuzüglich 19% MWST',
    total: 'Gesamtsumme inkl. MWST',
  },
  positionList: {
    header: {
      description: 'Position',
      count: 'Menge',
      countUnit: 'Einheit (Menge)',
      unitPriceNet: 'Einzelpreis (netto)',
      unitPriceNetUnit: 'Einheit (Einzelpreis)',
      totalPriceNet: 'Gesamtsumme (netto)',
      vat: 'Mwst.-Art',
      vatRate: 'Mwst.-Satz',
    },
    edit: {
      addInvoicePosition: {
        label: 'Neue Position',
      },
      description: {
        label: 'Position',
      },
      count: {
        label: 'Menge',
      },
      countUnit: {
        label: 'Einheit (Menge)',
      },
      unitPriceNet: {
        label: 'Einzelpreis (netto)',
      },
      unitPriceNetUnit: {
        label: 'Einheit (Einzelpreis)',
      },
      totalPriceNet: {
        label: 'Gesamtsumme (netto)',
      },
      vat: {
        label: 'Mwst.',
      },
    },
  },
  email: {
    title: 'E-Mail senden',
    to: {
      label: 'An',
    },
    cc: {
      label: 'Kopie an',
    },
    subject: {
      label: 'Betreff',
    },
    body: {
      label: 'Inhalt',
    },
    send: {
      label: 'Senden',
    },
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
  event: {
    title: 'Rechnung - {invoiceReference}',
    details: {
      invoiceReference: 'Rechnungsnummer',
      invoiceState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      invoiceDate: 'Rechnungsdatum',
      timeForPayment: 'Fälligkeit',
    },
  },
};
