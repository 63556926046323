export default {
  title: 'Handkasse',
  currentBalance: 'aktueller Handkassenbestand',
  usageDialog: {
    usage: 'Verwendungszweck',
    close: 'schließen',
  },
  list: {
    delete: {
      title: 'Eintrag löschen',
      message: 'Soll der Handkasseneintrag wirklich gelöscht werden?',
    },
    header: {
      ctValue: 'Umsatz',
      ctValueAssigned: 'Beleg-Summe',
      ctUsage: 'Verwendungszweck',
      transactionDate: 'Buchungstag',
      businessUnit: 'Geschäftsstelle',
      cashAccountTransactionAssignmentState: 'Zuweisungsstatus',
    },
    edit: {
      acceptIncompleteAssignment: 'akzeptiere unvollständige Zuweisung',
    },
    filter: {
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      ctValueFrom: {
        label: 'Umsatz größer als',
      },
      ctValueTo: {
        label: 'Umsatz kleiner als',
      },
      ctUsage: {
        label: 'Verwendungszweck enthält',
      },
      transactionDateFrom: {
        label: 'Transaktionsdatum ab',
      },
      transactionDateUntil: {
        label: 'Transaktionsdatum bis',
      },
      invoiceId: {
        label: 'Rechnung',
      },
      creditId: {
        label: 'Gutschrift',
      },
      externalDocumentId: {
        label: 'Externer Beleg',
      },
    },
  },
  form: {
    titleNew: 'Neuer Eintrag',
    ctValue: {
      label: 'Umsatz',
    },
    ctUsage: {
      label: 'Verwendungszweck',
    },
    transactionDate: {
      label: 'Buchungstag',
    },
    businessUnit: {
      label: 'Geschäftsstelle',
    },
    cashAccountTransactionInvoicePositions: {
      label: 'Zuweisungen - Rechnungen',
    },
    cashAccountTransactionCreditPositions: {
      label: 'Zuweisungen - Gutschriften',
    },
    cashAccountTransactionExternalDocumentPositions: {
      label: 'Zuweisungen - Externe Belege',
    },
    notAllBusinessUnitAssignmentsValid: 'Achtung: nicht alle zugewiesenen Belege gehören zur ausgewählten Handkasse der Geschäftstelle {0}.',
    createExternalDocument: {
      label: 'Neuanlage Externer Beleg',
    },
  },
  enums: {
    CashAccountTransactionAssignmentState: {
      NOT_ASSIGNED: 'nicht zugewiesen',
      ASSIGNMENT_INCOMPLETE_MANUAL: 'unvollständig',
      ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED: 'unvollständig, aber akzeptiert',
      ASSIGNMENT_COMPLETE_MANUAL: 'vollständig',
    },
    notAllBusinessUnitAssignmentsValid: 'Achtung: nicht alle zugewiesenen Belege gehören zur ausgewählten Handkasse der Geschäftstelle {0}.',
  },
  positionList: {
    header: {
      invoice: 'Rechnung',
      credit: 'Gutschrift',
      externalDocument: 'Externer Beleg',
      businessUnit: 'Geschäftsstelle',
      assignedPriceNet: 'Belegwert (netto)',
      assignedPriceGross: 'Belegwert (brutto)',
    },
    edit: {
      addCashTransactionPosition: {
        label: 'Neue Zuweisung',
      },
      assignedPriceNet: {
        label: 'Belegwert (netto)',
      },
      assignedPriceGross: {
        label: 'Belegwert (brutto)',
      },
    },
  },
};
