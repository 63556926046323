import de from 'vuetify/src/locale/de';

import calendar from '../modules/calendar/locale/de';
import documenttranslation from '../modules/contract/documenttranslation/locale/de';
import onsitetranslation from '../modules/contract/onsitetranslation/locale/de';
import videotranslation from '../modules/contract/videotranslation/locale/de';
import customer from '../modules/customer/locale/de';
import customergroup from '../modules/customer/customergroup/locale/de';
import language from '../modules/language/locale/de';
import businessunit from '../modules/businessunit/locale/de';
import settings from '../modules/settings/locale/de';
import translator from '../modules/translator/locale/de';
import user from '../modules/user/locale/de';
import officeworker from '../modules/officeworker/locale/de';
import admin from '../modules/admin/locale/de';
import systemconfiguration from '../modules/systemconfiguration/locale/de';
import report from '../modules/report/locale/de';
import bankaccounttransaction from '../modules/bankaccounttransaction/locale/de';
import cashaccounttransaction from '../modules/cashaccounttransaction/locale/de';
import invoice from '../modules/financialdocument/invoice/locale/de';
import credit from '../modules/financialdocument/credit/locale/de';
import contact from '../modules/contact/locale/de';
import costcenter from '../modules/costcenter/locale/de';
import emailtemplate from '../modules/emailtemplate/locale/de';
import email from '../modules/email/locale/de';
import externaldocument from '../modules/financialdocument/externaldocument/locale/de';
import task from '../modules/task/locale/de';
import starmoneyimport from '../modules/starmoneyimport/locale/de';
import cashbook from '../modules/cashbook/locale/de';
import vatconfiguration from '../modules/vatconfiguration/locale/de';

export default {
  $vuetify: {
    close: 'Schließen',
    dataIterator: {
      pageText: '{0}-{1} von {2}',
      noResultsText: 'Keine Elemente gefunden',
      loadingText: 'Lade Elemente...',
    },
    dataTable: {
      itemsPerPageText: 'Zeilen pro Seite:',
      ariaLabel: {
        sortDescending: ': Absteigend sortiert. Aktivieren um Sortierung zu entfernen.',
        sortAscending: ': Aufsteigend sortiert. Aktivieren um absteigend zu sortieren.',
        sortNone: ': Nicht sortiert. Aktivieren um aufsteigend zu sortieren.',
      },
      sortBy: 'Sortiere nach',
    },
    dataFooter: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageAll: 'Alle',
      nextPage: 'Nächste Seite',
      prevPage: 'Vorherige Seite',
      firstPage: 'Erste Seite',
      lastPage: 'Letzte Seite',
    },
    datePicker: {
      itemsSelected: '{0} ausgewählt',
    },
    noDataText: 'Tippe um zu suchen',
    carousel: {
      prev: 'Vorheriges Bild',
      next: 'Nächstes Bild',
    },
    calendar: {
      moreEvents: '{0} Einträge',
    },
    fileInput: {
      counter: '{0} Dateien',
      counterSize: '{0} Dateien ({1} gesamt)',
    },
  },
  title: '{company} - Bürosoftware',
  module: {
    calendar,
    documenttranslation,
    onsitetranslation,
    videotranslation,
    customer,
    customergroup,
    language,
    businessunit,
    settings,
    translator,
    user,
    officeworker,
    admin,
    systemconfiguration,
    report,
    bankaccounttransaction,
    cashaccounttransaction,
    invoice,
    contact,
    costcenter,
    emailtemplate,
    email,
    credit,
    externaldocument,
    task,
    starmoneyimport,
    cashbook,
    vatconfiguration,
  },
  menu: {
    users: 'Benutzer',
    masterData: 'Stammdaten',
    contracts: 'Aufträge',
    accounting: 'Buchhaltung',
    dashboard: 'Dashboard',
    appointment: 'Termin',
    settings: 'Einstellungen',
  },
  login: {
    title: 'Anmelden',
    error: 'Ungültige E-Mail-Adresse oder Passwort.',
    ref: {
      expired: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
      reset: 'Ihr Passwort wurde zurückgesetzt. Sie können sich jetzt wie gewohnt anmelden.',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
    password: {
      label: 'Passwort',
      hintCapslock: 'Feststelltaste gedrückt?',
    },
    submit: {
      label: 'Anmelden',
    },
    requestPassword: {
      label: 'Passwort vergessen',
      success: 'Eine E-Mail wurde an {email} versandt, falls diese im System bekannt ist. Bitte folgen Sie dem Link in der E-Mail, um Ihr Password neu zu vergeben. Der Link ist gültig bis zum {expires}.',
    },
  },
  resetPassword: {
    title: 'Passwort zurücksetzen',
    titleActivation: 'Nutzer aktivieren - Passwort setzen',
    password: {
      label: 'Neues Passwort',
    },
    passwordConfirm: {
      label: 'Neues Passwort wiederholen',
    },
    submit: {
      label: 'Passwort zurücksetzen',
    },
    toLogin: {
      label: 'Anmelden',
    },
  },
  logout: {
    tooltip: 'Abmelden',
  },

  hintOnlyReadOnly: 'Hinweis: Sie haben nur Leserechte.',

  // buttons
  submit: {
    label: 'Speichern',
  },
  reset: {
    label: 'Zurücksetzen',
  },
  refresh: {
    label: 'Aktualisieren',
  },
  add: {
    label: 'Hinzufügen',
  },
  edit: {
    label: 'Bearbeiten',
  },
  delete: {
    label: 'Löschen',
  },
  cancel: {
    label: 'Abbrechen',
  },
  close: {
    label: 'Schließen',
  },
  no: {
    label: 'Nein',
  },
  yes: {
    label: 'Ja',
  },
  ok: {
    label: 'OK',
  },
  filter: {
    label: 'Filtern',
  },

  // messages
  unsavedChanges: {
    title: 'Ungespeicherte Änderungen',
    msg: 'Es sind ungespeicherte Änderungen vorhanden. Sollen diese jetzt gespeichert werden?',
  },

  // shared
  list: {
    loading: 'Lade Daten...',
    header: {
      id: 'ID',
    },
    filter: 'Filter',
    delete: {
      title: 'Löschen', // of the confirmation dialog
      success: 'Eintrag gelöscht',
    },
  },
  form: {
    save: {
      success: 'Änderungen gespeichert',
    },
  },

  // quill editor
  quill: {
    font: {
      tooltip: 'Schriftart',
      default: 'Normal',
      serif: 'Serif',
      monospace: 'Monospace',
    },
    header: {
      tooltip: 'Überschrift',
      default: 'Normal',
      1: 'Überschrift 1',
      2: 'Überschrift 2',
      3: 'Überschrift 3',
      4: 'Überschrift 4',
      5: 'Überschrift 5',
      6: 'Überschrift 6',
    },
    size: {
      tooltip: 'Schriftgröße',
      default: 'Normal',
      small: 'Klein',
      large: 'Groß',
      huge: 'Extra groß',
    },
    bold: {
      tooltip: 'Fett',
    },
    italic: {
      tooltip: 'Kursiv',
    },
    underline: {
      tooltip: 'Unterstrichen',
    },
    strike: {
      tooltip: 'Durchgestrichen',
    },
    align: {
      tooltip: {
        default: 'Linksbündig',
        center: 'Zentriert',
        right: 'Rechtsbündig',
        justify: 'Blocksatz',
      },
    },
    script: {
      tooltip: {
        sub: 'Tiefgestellt',
        super: 'Hochgestellt',
      },
    },
    color: {
      tooltip: 'Textfarbe',
    },
    background: {
      tooltip: 'Hintergrundfarbe',
    },
    list: {
      tooltip: {
        ordered: 'Aufzählung',
        bullet: 'Auflistung',
      },
    },
    indent: {
      tooltip: {
        indent: 'Einzug vergrößern',
        outdent: 'Einzug verringern',
      },
    },
    link: {
      tooltip: 'Hyperlink',
      title: 'Link einfügen',
      label: 'URL (inkl. http://)',
      delete: {
        label: 'Link entfernen',
      },
    },
    image: {
      tooltip: 'Grafik einfügen',
      errorSize: 'Die Dateigröße darf max. {maxFileSize} betragen.',
    },
    clean: {
      tooltip: 'Formatierung entfernen',
    },
  },

  error: {
    // server exceptions
    server: {
      NOT_FOUND: {
        ADMIN: 'Administrator mit der ID {0} nicht gefunden.',
        BUSINESS_UNIT: 'Geschäftsstelle mit der ID {0} nicht gefunden.',
        BANK_ACCOUNT: 'Bankverbindung mit der ID {0} nicht gefunden.',
        DOCUMENT_TRANSLATION_CONTRACT: 'Auftrag mit der ID {0} nicht gefunden.',
        ON_SITE_TRANSLATION_CONTRACT: 'Auftrag mit der ID {0} nicht gefunden.',
        VIDEO_TRANSLATION_CONTRACT: 'Auftrag mit der ID {0} nicht gefunden.',
        CUSTOMER: 'Kunde mit der ID {0} nicht gefunden.',
        LANGUAGE: 'Sprache mit der ID {0} nicht gefunden.',
        TRANSLATOR: 'Dolmetscher mit der ID {0} nicht gefunden.',
        COST_CENTER: 'Kostenstelle mit der ID {0} nicht gefunden.',
        COST_CONFIGURATION_CYCLE_TYPE: 'Abrechnungszyklus mit der ID {0} nicht gefunden.',
        CUSTOMER_GROUP: 'Kundengruppe mit der ID {0} nicht gefunden.',
        EMAIL_TEMPLATE: 'E-Mail-Vorlage mit der ID {0} nicht gefunden.',
        INVOICE: 'Rechnung mit der ID {0} nicht gefunden.',
        CREDIT: 'Gutschrift mit der ID {0} nicht gefunden.',
        OFFICE_WORKER: 'Sachbearbeiter mit der ID {0} nicht gefunden.',
        SYSTEM_CONFIGURATION: 'Systemeinstellung "{0}" nicht gefunden.',
        REPORT: 'Report "{0}" nicht gefunden.',
      },
      MAPPING: {
        ACCOUNTING_DELETE: 'module.user.enums.UserPermission.ACCOUNTING_DELETE',
      },
      BUSINESS_UNIT: {
        DELETE_NOT_POSSIBLE_BECAUSE_CONTRACTS_EXISTS: 'Geschäftsstelle kann nicht gelöscht werden, da sie noch in Aufträgen zugewiesen ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_OFFICE_WORKERS_EXISTS: 'Geschäftsstelle kann nicht gelöscht werden, da sie noch Sachbearbeitern zugewiesen ist.',
      },
      COST_CENTER: {
        DELETE_NOT_POSSIBLE_BECAUSE_CUSTOMERS_EXISTS: 'Kostenstelle kann nicht gelöscht werden, da sie noch Kunden zugewiesen ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_TRANSLATORS_EXISTS: 'Kostenstelle kann nicht gelöscht werden, da sie noch Dolmetschern zugewiesen ist.',
      },
      CUSTOMER_GROUP: {
        DELETE_NOT_POSSIBLE_BECAUSE_TRANSLATOR_COST_CONFIGURATION_EXISTS: 'Kundengruppe kann nicht gelöscht werden, da sie noch in Dolmetscher-Kostenkonfigurationen zugewiesen ist.',
      },
      CUSTOMER_LANGUAGE_GROUP: {
        DELETE_NOT_POSSIBLE_BECAUSE_CUSTOMER_COST_CONFIGURATION_EXISTS: 'Sprachegruppe kann nicht gelöscht werden, da sie noch in Kunden-Kostenkonfigurationen zugewiesen ist.',
        DUPLICATE_LANGUAGE_CHECK: 'Sprache {1} mehrfach in verschiedenen Sprachgruppen vergeben. Speichern abgebrochen.',
      },
      LANGUAGE: {
        DELETE_NOT_POSSIBLE_BECAUSE_CONTRACTS_EXISTS: 'Sprache kann nicht gelöscht werden, da sie noch in Aufträgen zugewiesen ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_CUSTOMER_LANGUAGE_GROUPS_EXISTS: 'Sprache kann nicht gelöscht werden, da sie noch in Kundensprachgruppen zugewiesen ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_TRANSLATOR_EXISTS: 'Sprache kann nicht gelöscht werden, da sie noch Dolmetschern zugewiesen ist.',
      },
      USER: {
        INVALID_USER_TYPE: 'Typ des Benutzers muss "{0}" sein.',
        INVALID_USER_TYPE_CONTACT: 'Kontaktinformation für Benutzertyp "{0}" nicht verfügbar.',
        DELETE_NOT_POSSIBLE_BECAUSE_CONTRACTS_EXISTS: 'Nutzer kann nicht gelöscht werden, da er noch in Aufträgen zugewiesen ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_CREDITS_EXISTS: 'Nutzer kann nicht gelöscht werden, da er noch in Gutschriften zugewiesen ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_INVOICES_EXISTS: 'Nutzer kann nicht gelöscht werden, da er noch in Rechnungen zugewiesen ist.',
        NO_PERMISSION: 'Berechtigung fehlt ({0})!',
        DUPLICATE_USER_ADMIN: 'Es existiert bereits ein Nutzer (Administrator) mit der E-Mail-Adresse {0}. Bitte wählen Sie eine andere E-Mail-Adresse.',
        DUPLICATE_USER_OFFICE_WORKER: 'Es existiert bereits ein Nutzer (Sachbearbeiter) mit der E-Mail-Adresse {0}. Bitte wählen Sie eine andere E-Mail-Adresse.',
        DUPLICATE_USER_CUSTOMER: 'Es existiert bereits ein Nutzer (Kunde) mit der E-Mail-Adresse {0}. Bitte wählen Sie eine andere E-Mail-Adresse.',
        DUPLICATE_USER_TRANSLATOR: 'Es existiert bereits ein Nutzer (Dolmetscher) mit der E-Mail-Adresse {0}. Bitte wählen Sie eine andere E-Mail-Adresse.',
      },
      PASSWORD_CHANGE: {
        USER_WITH_EMAIL_NOT_FOUND: 'Die E-Mail-Adresse {0} ist nicht vergeben.',
        ACTION_NOT_ALLOWED_FOR_LOCKED_USER: 'Diese Aktion ist nicht zulässig.',
        OLD_PASSWORD_WRONG: 'Das aktuelle Passwort ist nicht korrekt.',
        PASSWORD_LINK_NOT_VALID: 'Der aufgerufene Link ist ungültig.',
        PASSWORD_LINK_EXPIRED: 'Der aufgerufene Link ist abgelaufen.',
      },
      FINANCIAL_DOCUMENT: {
        DELETE_NO_POSSIBLE_BECAUSE_ASSIGNMENT_TO_BANK_ACCOUNT_TRANSACTION_EXISTS:
          'Löschen des Belegs ist nicht möglich, da noch er noch einem Konto-Umsatz zugewiesen ist. Diese Zuweisung muss ggf. zuerst gelöscht werden.',
        DELETE_NO_POSSIBLE_BECAUSE_ASSIGNMENT_TO_CASH_ACCOUNT_TRANSACTION_EXISTS:
          'Löschen des Belegs ist nicht möglich, da noch er noch einem Handkassen-Umsatz zugewiesen ist. Diese Zuweisung muss ggf. zuerst gelöscht werden.',
      },
      INVOICE: {
        APPROVEMENT_WITHOUT_PDF_CREATION_NOT_ALLOWED: 'Rechnung können erst bestätigt werden, wenn eine Rechnungs-PDF erstellt wurde.',
        NO_CONTRACTS_DEFINED: 'Keine Aufträge ausgewählt.',
        NO_TRANSLATOR_DEFINED: 'Kein Dolmetscher am Auftrag ausgewählt.',
        COST_CENTER_UNDEFINED: 'Die Kostenstelle des Kunden ist nicht definiert.',
        RECEIVER_OPTION_UNDEFINED: 'Es wurde keine Option für die Rechnungsadresse ausgewählt.',
        INVOICE_CREATION_NOT_ALLOWED_FOR_CURRENT_CONTRACT_STATE: 'Eine Rechnungerstellung ist nur für abgeschlossene und stornierte Aufträge erlaubt.',
        CUSTOMER_LANGUAGE_GROUP_NOT_FOUND: 'Die Sprache {1} wurde am Kunden {2} noch keiner Sprachgruppe zugeordnet.',
        CUSTOMER_LANGUAGE_GROUP_AMBIGUOUS: 'Die Sprache {1} wurde am Kunden {2} mehreren Sprachgruppen zugeordnet.',
        COST_CONFIG_CYCLE_NOT_FOUND: 'Am Kunden {0} wurde für die Sprachgruppe {1} kein Abrechnungszyklus {2} definiert.',
        COST_CONFIG_CYCLE_AMBIGUOUS: 'Am Kunden {0} wurden für die Sprachgruppe {1} mehrere Abrechnungszyklen {2} definiert.',
        INVALID_INVOICE_REFERENCE: 'Rechnungsnummer muss eindeutige Zahl {0} enthalten.',
        REVERSAL_INVOICE_REFERENCE_ALREADY_EXISTS: 'Erstellen der Stornorechnung ist nicht möglich, da bereits eine Rechnung mit Nummer {0} existiert.',
        REVERSAL_INVOICE_ALREADY_EXISTS: 'Erstellen der Stornorechnung ist nicht möglich, da bereits eine Storno-Rechnung mit Nummer {1} zur Rechnung mit Nummer {0} existiert.',
        ONLY_APPROVED_INVOICES_CAN_BE_CANCELED: 'Erstellen der Stornorechnung ist nicht möglich, da Rechnung mit Nummer {0} nicht im Status bestätigt ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_REVERSAL_INVOICE_EXISTS: 'Löschen der Rechnung ist nicht möglich, da noch eine zugehörige Storno-Rechnung existiert. Diese muss ggf. zuerst gelöscht werden.',
        WAIT_TIME_COULD_NOT_BE_GREATER_THAN_TRANSLATION_TIME: 'Wartezeit darf nicht größer sein als die Arbeitszeit.',
        COST_PER_KM_NOT_SET: 'Erstellung Rechnung für Auftrag {0} nicht möglich - Eingabe fehlt: Anreiseweg für Dolmetscher in km',
        FIX_RATE_NOT_SET: 'Erstellung Rechnung für Auftrag {0} nicht möglich - Eingabe fehlt: Anreise-Pauschale in €',
        PUBLIC_TRANSIT_TICKET_COST_NOT_SET: 'Erstellung Rechnung für Auftrag {0} nicht möglich - Eingabe fehlt: Ticketkosten',
      },
      CREDIT: {
        APPROVEMENT_WITHOUT_PDF_CREATION_NOT_ALLOWED: 'Die Bestätigung ist nicht erlaubt, da noch keine PDF der Gutschrift erstellt wurde',
        COST_CENTER_UNDEFINED: 'Die Kostenstelle des Kunden ist nicht definiert.',
        COST_CONFIG_CYCLE_AMBIGUOUS: 'Am Dolmetscher {0} wurden für die Kundengruppe {1} mehrere Abrechnungszyklen für {2} definiert .',
        COST_CONFIG_CYCLE_NOT_FOUND: 'Am Dolmetscher {0} wurde für die Kundengruppe {1} kein Abrechnungszyklus für {2} definiert.',
        CREDIT_CREATION_NOT_ALLOWED_FOR_CURRENT_CONTRACT_STATE: 'Eine Gutschrifterstellung ist nur für abgeschlossene und stornierte Aufträge erlaubt.',
        NO_CONTRACTS_DEFINED: 'Keine Aufträge ausgewählt.',
        NO_TRANSLATOR_DEFINED: 'Kein Dolmetscher am Auftrag ausgewählt.',
        RECEIVER_OPTION_UNDEFINED: 'Es wurde keine Option für die Gutschriftadresse ausgewählt.',
        WAIT_TIME_COULD_NOT_BE_GREATER_THAN_TRANSLATION_TIME: 'Wartezeit darf nicht größer sein als die Arbeitszeit.',
        COST_PER_KM_NOT_SET: 'Erstellung Gutschrift für Auftrag {0} nicht möglich - Eingabe fehlt: Anreiseweg für Dolmetscher in km',
        FIX_RATE_NOT_SET: 'Erstellung Gutschrift für Auftrag {0} nicht möglich - Eingabe fehlt: Anreise-Pauschale in €',
        PUBLIC_TRANSIT_TICKET_COST_NOT_SET: 'Erstellung Gutschrift für Auftrag {0} nicht möglich - Eingabe fehlt: Ticketkosten',
        REVERSAL_CREDIT_REFERENCE_ALREADY_EXISTS: 'Erstellen der Stornogutschrift ist nicht möglich, da bereits eine Gutschrift mit Nummer {0} existiert.',
        REVERSAL_CREDIT_ALREADY_EXISTS: 'Erstellen der Stornogutschrift ist nicht möglich, da bereits eine Storno-Gutschrift mit Nummer {1} zur Gutschrift mit Nummer {0} existiert.',
        ONLY_APPROVED_CREDIT_CAN_BE_CANCELED: 'Erstellen der Stornogutschrift ist nicht möglich, da Gutschrift mit Nummer {0} nicht im Status bestätigt ist.',
        DELETE_NOT_POSSIBLE_BECAUSE_REVERSAL_CREDIT_EXISTS: 'Löschen der Gutschrift ist nicht möglich, da noch eine zugehörige Storno-Gutschrift existiert. Diese muss ggf. zuerst gelöscht werden.',
      },
      CONTACT: {
        MISSING_CITY: 'Eingabe Stadt fehlt.',
        MISSING_ZIP_CODE: 'Eingabe Postleitzahl fehlt.',
        MISSING_STREET: 'Eingabe Straße fehlt.',
        MISSING_COMPANY_OR_INCOMPLETE_NAME_OF_PERSON: 'Eingaben zur Firma oder Namen unvollständig.',
        INCONSISTENT_CITY: 'Adressangaben zu Städten sind nicht identisch.',
        INCONSISTENT_ZIP_CODE: 'Adressangaben zu Postleitzahlen sind nicht identisch.',
        INCONSISTENT_STREET: 'Adressangaben zu Straßen sind nicht identisch.',
        INCONSISTENT_COUNTRY: 'Adressangaben zu Ländern sind nicht identisch.',
        INCONSISTENT_LAST_NAME: 'Adressangaben zu  Nachnamen sind nicht identisch.',
        INCONSISTENT_FIRST_NAME: 'Adressangaben zu Vornamen sind nicht identisch.',
        INCONSISTENT_COMPANY: 'Adressangaben zu Firmen sind nicht identisch.',
      },
      CONTRACT: {
        FULFILLMENT_CHECK_MISSING_LINES_OF_TRANSLATED_FILE: 'Um Auftrag {0} abzuschließen müssen noch die Anzahl der Zeilen der Übersetzung eingetragen werden.',
        FULFILLMENT_CHECK_MISSING_REAL_TRANSLATION_DURATION_ON_SITE: 'Um Auftrag {0} abzuschließen müssen noch die tatsächliche Dauer in Minuten eingetragen werden.',
      },
      FILE: {
        NOT_FOUND: 'Datei nicht gefunden',
        FILE_DELETE_FAILED: 'Löschen der Datei fehlgeschlagen.',
        FILE_SAVE_FAILED: 'Speichern der Datei fehlgeschlagen.',
        BASE_DIR_CREATE_FAILED: 'Verzeichnis-Anlage auf Server fehlgeschlagen.',
        DUPLICATE_ATTACHMENT: 'Anhang mit gleichem Namen ist nicht erlaubt.',
      },
      REPORT: {
        NOT_FOUND: 'Report nicht gefunden.',
        CREATE_FAILED: 'Report konnte nicht erstellt werden.',
        INVALID_REPORT_DATES: 'ungültige Datumsangaben für Erstellung eines Reports',
      },
      EMAIL: {
        SEND_FAILED: 'Email-Versand fehlgeschlagen.',
        SEND_DISABLED: 'Email-Versand ist deaktiviert. Bitte ggf. in Systemeinstellungen aktivieren.',
      },
      STAR_MONEY_IMPORT: {
        COMPLETED_IMPORT_NOT_ALLOWED_TO_DELETE: 'Erfolgreich importierte Datensätze dürfen aus Gründen der Nachvollziehbarkeit nicht gelöscht werden.',
      },
      VAT: {
        NO_VAT_RATE_FOUND: 'Es wurde kein gültiger Mehrwertsteuersatz für den {0} gefunden.',
        DUPLICATE_VAT_CONFIG_ENTRY: 'Doppelte Einträge für Gültig-Ab-datum und Mehrwertsteuerart sind nicht zulässig.',
      },
    },
  },

  // special keys

  $nth: (n: number) => `${n}.`,

  $dateTimeFormats: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    dateTime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    eventTime: {
      hour: 'numeric',
      minute: '2-digit',
    },
  },

  $numberFormats: {
    decimal: {
      style: 'decimal',
    },
    integer: {
      style: 'decimal',
      maximumFractionDigits: 0,
    },
    fileSize: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency: process.env.VUE_APP_CURRENCY || 'EUR',
      currencyDisplay: 'symbol',
    },
  },
};
