export default {
  title: 'Mehrwertsteuer-Konfiguration',
  list: {
    header: {
      validFrom: 'Gültig-Ab-Datum',
      vat: 'Mehrwertsteuer-Art',
      vatRate: 'Mehrwertsteuer-Satz',
    },
    delete: {
      title: 'Mehrwertsteuer-Konfiguration löschen',
      message: 'Soll die Mehrwertsteuer-Konfiguration wirklich gelöscht werden?',
    },
    filter: {
      vat: 'Mehrwertsteuer-Art',
    },
  },
  form: {
    validFrom: 'Gültig-Ab-Datum',
    vat: 'Mehrwertsteuer-Art',
    vatRate: 'Mehrwertsteuer-Satz',
  },
  enums: {
    Vat: {
      ZERO: 'keine',
      REDUCED: 'reduziert',
      FULL: 'voll',
    },
  },
};
