export default {
  title: 'Dolmetscher',
  list: {
    actions: {
      sendEmailToTranslator: 'Email an Dolmetscher senden',
    },
    header: {
      name: 'Name',
      email: 'E-Mail',
      mainPhoneNumber: 'Haupttelefonnummer',
      costCenter: 'Kostenstelle',
      translatorComment: 'Kommentar',
      languages: 'Sprachen',
      translatorContactLocation: 'Wohnort',
    },
    edit: {
      sendEmail: {
        title: 'Sende Email',
      },
    },
    filter: {
      firstName: {
        label: 'Vorname',
      },
      lastName: {
        label: 'Nachname',
      },
      email: {
        label: 'Email',
      },
      mainPhoneNumber: {
        label: 'Haupttelefonnummer enthält',
      },
      costCenter: {
        label: 'Kostenstelle',
      },
      translatorContactLocation: {
        label: 'Wohnort enthält',
      },
      translatorComment: {
        label: 'Kommentar enthält',
      },
      languages: {
        label: 'Sprachen',
      },
      translator: {
        label: 'Dolmetscher',
      },
    },
    offeredServices: {
      INTERPRETER: 'D',
      TRANSLATOR: 'Ü',
    },
    delete: {
      title: 'Dolmetscher löschen',
      message: 'Soll der Benutzer {fullName} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neuer Dolmetscher',
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
    mainPhoneNumber: {
      label: 'Haupttelefonnummer',
    },
    taxId: {
      label: 'Steuer-ID',
    },
    costCenter: {
      label: 'Kostenstelle',
    },
    bankAccountHolderInfoText: {
      label: 'Kontoinformationen für Gutschriftenerstellung',
    },
    bankAccountHolder: {
      label: 'Kontoinhaber',
    },
    bankName: {
      label: 'Name der Bank',
    },
    IBAN: {
      label: 'IBAN',
    },
    BIC: {
      label: 'BIC',
    },
    vatExempt: {
      label: 'mehrwertsteuerbefreit',
    },
    translatorComment: {
      label: 'Kommentar',
    },
    isLocked: {
      label: 'Nutzer ist gesperrt',
    },
    isWebDavEnabled: {
      label: 'WebDAV Kontakt',
    },
    sendActivationEmail: {
      label: 'sende Aktivierungs-Email',
    },
    languages: {
      label: 'Sprachen',
    },
    offeredServices: {
      label: 'Dienstleistungsart',
      INTERPRETER: 'Dolmetscher',
      TRANSLATOR: 'Übersetzer',
    },
  },
  commentDialog: {
    comment: 'Kommentar',
    close: 'Schließen',
  },
  costConfiguration: {
    title: 'Kostenkonfiguration',
    fixedRateNet: {
      label: 'Pauschale (netto)',
    },
    costConfigurationCycles: {
      label: 'Abrechnungszyklen',
      titleUnknown: 'Neuer Abrechnungszyklus',
      noData: 'Keine Abrechnungszyklen definiert',
      translatorCostConfigurationCycleDuplicates: 'Achtung: Kombination aus Kundengruppe und Abrechnungseinheit ist nicht eindeutig.',
      costConfigurationCycle: {
        customerGroup: {
          label: 'Kundengruppe',
        },
        costConfigurationCycleType: {
          label: 'Abrechnungseinheit',
        },
        firstCycleDuration: {
          label: 'Erste Taktung',
        },
        firstCycleCostNet: {
          label: 'Kosten für erste Taktung (netto)',
        },
        followingCycleDuration: {
          label: 'Weitere Taktung',
        },
        followingCycleCostNet: {
          label: 'Kosten pro weiterer Taktung (netto)',
        },
      },
    },
  },
  creditContact: {
    title: 'Kontakt für Gutschrift',
  },
  email: {
    title: 'E-Mail senden',
    to: {
      label: 'An',
    },
    cc: {
      label: 'Kopie an',
    },
    subject: {
      label: 'Betreff',
    },
    body: {
      label: 'Inhalt',
    },
    send: {
      label: 'Senden',
    },
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
};
