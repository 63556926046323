export default {
  title: 'Kassenbuch',
  form: {
    businessUnit: 'Geschäftsstelle',
    fromDate: 'von',
    toDate: 'bis',
    button: {
      submit: 'Erstelle Kassenbuch',
    },
  },
};
