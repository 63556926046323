import base from '../../locale/de';
import _ from 'lodash';

export default _.merge(_.cloneDeep(base), {
  title: 'Vorort-Dolmetschen',
  usageDialog: {
    usage: 'Kommentar',
    close: 'schließen',
  },
  warningDialog: {
    usage: 'Hinweis',
    close: 'schließen',
    creditAlreadyExists: 'Für die gewählten Aufträge existiert bereits mindestens eine Gutschrift.',
    createCreditForced: 'zusätzliche Gutschrift erstellen',
    invoiceAlreadyExists: 'Für die gewählten Aufträge existiert bereits mindestens eine Rechnung.',
    createInvoiceForced: 'zusätzliche Rechnung erstellen',
    hasMoreThanOneTranslator: 'Für die Gutschriftserstellung darf nur ein Dolmetscher ausgewählt sein. Bitte passen Sie Ihre Auftragsauswahl an.',
    hasMoreThanOneCustomer: 'Für die Rechnungserstellung darf nur ein Kunde ausgewählt sein. Bitte passen Sie Ihre Auftragsauswahl an.',
    isCreditAlreadyCreated: 'Es existiert bereits mindestens eine Gutschrift für die gewählten Aufträge.',
    isInvoiceAlreadyCreated: 'Es existiert bereits mindestens eine Rechnung für die gewählten Aufträge.',
  },
  enums: {
    TravelOption: {
      FIX_RATE: 'Pauschale',
      COST_PER_KM: 'Entfernungspauschale pro km',
      PUBLIC_TRANSIT_TICKET: 'Erstattung ÖPNV-Tickets',
      NO_REFUND: 'Keine Kostenerstattung',
    },
  },
  list: {
    header: {
      contractNo: 'Auftragsnummer',
      contractState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      translator: 'Dolmetscher',
      translatorLanguage: 'Sprache',
      contractDate: 'Auftragsdatum',
      beginDateTime: 'Liefertermin',
      contractComment: 'Kommentar',
      operationContact: 'Einsatzort',
    },
    filter: {
      contractNo: {
        label: 'Auftragsnummer',
      },
      contractState: {
        label: 'Status',
      },
      contractComment: {
        label: 'Kommentar enthält',
      },
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      customer: {
        label: 'Kunde',
      },
      translator: {
        label: 'Dolmetscher',
      },
      contractDateFrom: {
        label: 'Auftragsdatum ab',
      },
      contractDateUntil: {
        label: 'Auftragsdatum bis',
      },
      beginDateTimeFrom: {
        label: 'Liefertermin von',
      },
      beginDateTimeUntil: {
        label: 'Liefertermin bis',
      },
      invoiceFilter: {
        label: 'Rechnung-Erstellung',
      },
      creditFilter: {
        label: 'Gutschrift-Erstellung',
      },
      language: {
        label: 'Sprache',
      },
      invoiceId: {
        label: 'Rechnung',
      },
      creditId: {
        label: 'Gutschrift',
      },
      operationContactFilter: {
        label: 'Einsatzort enthält',
      },
    },
    invoiceCreated: 'Rechnung erstellt',
    creditCreated: 'Gutschrift erstellt',
    invoiceAndCreditCreated: 'Rechnung & Gutschrift erstellt',
    edit: {
      contractState: {
        fulfill: 'Auftrag erfüllt',
        cancel: 'Auftrag annullieren',
        confirmCancel: {
          title: 'Auftrag annullieren',
          message: 'Soll der Auftrag {contractNo} jetzt annulliert werden?',
          yes: {
            label: 'Auftrag annullieren',
          },
        },
      },
      sendEmail: {
        title: 'E-Mail-Versand',
      },
      translator: {
        assign: 'zuweisen',
        confirm: 'Dolmetscher bestätigen',
        label: 'Dolmetscher auswählen',
      },
    },
    actions: {
      showInvoice: 'Rechnung anzeigen',
      showCredit: 'Gutschrift anzeigen',
      sendEmailToTranslator: 'E-Mail-Versand an Dolmetscher',
      sendEmailToCustomer: 'E-Mail-Versand an Kunden',
    },
    createInvoice: {
      label: 'Rechnung erstellen',
    },
    createCredit: {
      label: 'Gutschrift erstellen',
    },
  },
  form: {
    titleNew: 'Neuer Auftrag',
    beginDateTime: {
      label: 'geplanter Termin (Liefertermin)',
    },
    plannedTranslationDuration: {
      label: 'Geplante Dauer in Min.',
    },
    realTranslationStart: {
      label: 'Beginn Arbeitszeit',
    },
    realTranslationEnd: {
      label: 'Ende Arbeitszeit',
    },
    travelTimeToLocationStart: {
      label: 'Beginn Hinreise',
    },
    travelTimeToLocationEnd: {
      label: 'Ende Hinreise',
    },
    travelTimeBackStart: {
      label: 'Begin Rückreise',
    },
    travelTimeBackEnd: {
      label: 'Ende Rückreise',
    },
    travelDistance: {
      label: 'Anreiseweg für Dolmetscher in km',
    },
    waitTimeAtLocation: {
      label: 'Wartezeit für Dolmetscher in Min.',
    },
    travelOption: {
      label: 'Reisekostenerstattung',
    },
    isCustomerTravelCost: {
      label: 'Kunden-Rechnung',
    },
    isTranslatorTravelCost: {
      label: 'Dolmetscher-Gutschrift',
    },
    fixedTravelCost: {
      label: 'Pauschale in €',
    },
    travelTicketCost: {
      label: 'Ticketkosten in €',
    },
    save: {
      success: 'Der Auftrag mit der Nummer {contractNo} wurde gespeichert.',
    },
    contractComment: {
      label: 'Kommentar',
    },
    afterFulfillmentText: {
      label: 'Erst nach Auftragsabschluss einzutragen.',
    },
    createCustomer: {
      label: 'Kundenneuanlage',
    },
  },
  customerCommentDialog: {
    content: 'Kommentar - Kunde',
    close: 'Schließen',
  },
  translatorCommentDialog: {
    content: 'Kommentar - Dolmetscher',
    close: 'Schließen',
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
  fileAttachmentCollection: {
    title: 'Einsatzdokumente',
  },
  file: {
    title: 'Dateien',
  },
  operationContact: {
    title: 'Einsatzort',
  },
  invoiceContact: {
    title: 'Rechnungskontakt',
  },
  creditContact: {
    title: 'Kontakt für Gutschrift',
  },
  email: {
    title: 'E-Mail senden',
    to: {
      label: 'An',
    },
    cc: {
      label: 'Kopie an',
    },
    subject: {
      label: 'Betreff',
    },
    body: {
      label: 'Inhalt',
    },
    send: {
      label: 'Senden',
    },
  },
  event: {
    details: {
      contractState: 'Status',
      businessUnit: 'Geschäftsstelle',
      customer: 'Kunde',
      translator: 'Dolmetscher',
      contractDate: 'Auftragsdatum',
      beginDateTime: 'geplanter Beginn',
    },
  },
  validate: {
    error: {
      validateStartEndExistsForStart: 'Wenn Beginn existiert, muss auch Ende existieren',
      validateStartEndExistsForEnd: 'Wenn Ende existiert, muss auch Beginn existieren',
      validateStartBeforeEnd: 'Beginn muss vor dem Ende liegen',
      validateEndAfterStart: 'Ende muss nach dem Start liegen',
    },
  },
});
