import base from '../../../locale/de';
import _ from 'lodash';

export default {
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
  send: {
    ok: 'Email erfolgreich versandt.',
    failed: 'Email konnte nicht versendet werden.',
  },
  error: {
    toBig: 'Emails größer als 7 MB können ggf. nicht erfolgreich versandt werden.',
  },
};
