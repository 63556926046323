export default {
  title: 'Gutschriften',
  download: 'Download - {0}',
  dialog: {
    cancelCredit: {
      title: 'Gutschrift stornieren',
      text: 'Möchten Sie die Gutschrift wirklich stornieren?',
      cancel: 'Stornieren',
      close: 'Schließen',
    },
    setStateAdmin: {
      title: 'Status setzen - ADMIN-AKTION!',
      text: 'Bitte wählen sie den gewünschten Status.',
      close: 'Schließen',
    },
    showDownloadPdfZipCheck: {
      title: 'Hinweis zum Sammel-Download',
      text: 'Für folgende Gutschriften wurden keine PDF gefunden:',
      downloadForced: 'Alle anderen downloaden',
      close: 'Schließen',
    },
    warning: {
      title: 'Warnung!',
      creditNotApproved: 'Gutschriften müssen bestätigt sein, damit sie storniert werden können.',
      close: 'Schließen',
    },
  },
  usageDialog: {
    usage: 'Kommentar',
    close: 'schließen',
  },
  message: {
    reversalCreditCreated: 'Stornogutschrift erstellt.',
  },
  enums: {
    CreditState: {
      CREATED: 'erstellt',
      CANCELED: 'storniert',
      APPROVED: 'bestätigt',
    },
  },
  list: {
    reversalTo: 'Storno zu',
    setStateAdmin: {
      label: 'Status setzen (ADMIN)',
    },
    cancelCredit: {
      label: 'Gutschrift stornieren',
    },
    downloadAccumulativePDF: {
      label: 'Download-PDF (ZIP)',
    },
    actions: {
      sendEmailToTranslator: 'Email an Dolmetscher senden',
    },
    header: {
      creditReference: 'Gutschriftnummer',
      creditState: 'Status',
      creditDate: 'Gutschriftdatum',
      translator: 'Übersetzer',
      businessUnit: 'Geschäftsstelle',
      creditComment: 'Kommentar',
      isPaymentCompleted: 'Bezahlung erfolgt',
      downloadPDF: 'PDF',
     },
    delete: {
      title: 'Gutschrift löschen',
      message: 'Soll die Gutschrift wirklich gelöscht werden?',
    },
    edit: {
      creditState: {
        fulfill: 'Gutschrift bestätigen',
      },
      sendEmail: {
        title: 'Sende Email',
      },
    },
    filter: {
      creditReference: {
        label: 'Gutschriftnummer',
      },
      creditState: {
        label: 'Status',
      },
      creditComment: {
        label: 'Kommentar',
      },
      contractNo: {
        label: 'Auftragsnummer',
      },
      translator: {
        label: 'Übersetzer',
      },
      businessUnit: {
        label: 'Geschäftsstelle',
      },
      creditDateFrom: {
        label: 'Gutschriftdatum ab',
      },
      creditDateUntil: {
        label: 'Gutschriftdatum bis',
      },
      isPaymentCompleted: {
        label: 'Bezahlung erfolgt',
        true: 'Ja',
        false: 'Nein',
      },
      pdfCreatorAdmin: {
        label: 'Gutschriften-PDF-Ersteller (Admin)',
      },
      pdfCreatorOfficeWorker: {
        label: 'Gutschriften-PDF-Ersteller (Sachbearbeiter)',
      },
      isCreatedPDF: {
        label: 'Gutschriften-PDF erstellt',
        true: 'Ja',
        false: 'Nein',
      },
    },
  },
  form: {
    headerInfo: {
      label: 'Gutschriftinformationen',
    },
    senderContact: {
      label: 'Absender',
    },
    receiverContact: {
      label: 'Empfänger',
    },
    creditPositions: {
      label: 'Positionen',
    },
    createPDF: {
      label: 'PDF erstellen',
    },
    creditFiles: {
      label: 'Gutschrift Download',
    },
    creditComment: {
      label: 'Kommentar',
    },
    creditDate: {
      label: 'Gutschriftendatum',
    },
    creditReference: {
      label: 'Gutschriftennummer',
    },
    taxId: {
      label: 'Steuer-ID',
    },
    translatorAsText: {
      label: 'Name des Übersetzers',
    },
    accountingPeriodFrom: {
      label: 'Abrechnungszeitraum von',
    },
    accountingPeriodUntil: {
      label: 'Abrechnungszeitraum bis',
    },
    bankAccountHolder: {
      label: 'Kontoinhaber',
    },
    bankName: {
      label: 'Name der Bank',
    },
    IBAN: {
      label: 'IBAN',
    },
    BIC: {
      label: 'BIC',
    },
    reference: {
      label: 'Referenz',
    },
    notice: {
      label: 'Bemerkung',
    },
    isPaymentCompleted: {
      label: 'Bezahlung erfolgt',
    },
    fileCreatedBy: {
      label: 'Ersteller: {0}',
    },
  },
  creditSummary: {
    header: 'Zusammenfassung',
    subtotal: 'Zwischensumme',
    vat: 'zuzüglich MWST',
    total: 'Gesamtsumme inkl. MWST',
  },
  positionList: {
    header: {
      operationReference: 'Einsatz',
      operationDate: 'Einsatz-Datum',
      operationDescription: 'Einsatz-Ort/Art',
      count: 'Menge',
      countUnit: 'Einheit (Menge)',
      unitPriceNet: 'Einzelpreis (netto)',
      unitPriceNetUnit: 'Einheit (Einzelpreis)',
      totalPriceNet: 'Gesamtsumme (netto)',
      vat: 'Mwst.-Art',
      vatRate: 'Mwst.-Satz',
    },
    edit: {
      addCreditPosition: {
        label: 'Neue Position',
      },
      operationReference: {
        label: 'Einsatz',
      },
      operationDate: {
        label: 'Einsatz-Datum',
      },
      operationDescription: {
        label: 'Einsatz-Ort/Art',
      },
      count: {
        label: 'Menge',
      },
      countUnit: {
        label: 'Einheit (Menge)',
      },
      unitPriceNet: {
        label: 'Einzelpreis (netto)',
      },
      unitPriceNetUnit: {
        label: 'Einheit (Einzelpreis)',
      },
      totalPriceNet: {
        label: 'Gesamtsumme (netto)',
      },
      vat: {
        label: 'Mwst.',
      },
    },
  },
  email: {
    title: 'E-Mail senden',
    to: {
      label: 'An',
    },
    cc: {
      label: 'Kopie an',
    },
    subject: {
      label: 'Betreff',
    },
    body: {
      label: 'Inhalt',
    },
    send: {
      label: 'Senden',
    },
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
};
