export default {
  title: 'StarMoney Import',
  list: {
    header: {
      fileName: 'Dateiname',
      importState: 'Status',
      uploadedBy: 'hochgeladen von',
      uploadedAt: 'hochgeladen am',
    },
    delete: {
      title: 'StarMoney Import löschen',
      message: 'Soll der StarMoney Import wirklich gelöscht werden?',
    },
    filter: {
      fileName: 'Dateiname enthält',
      importState: 'Status',
      uploadedAtFrom: 'hochgeladen am - von',
      uploadedAtTo: 'hochgeladen am - bis',
    },
  },
  fileform: {
    filename: 'Dateiname',
  },
  enums: {
    StarMoneyImportState: {
      READY_FOR_IMPORT: 'Bereit für Import',
      ERROR_WRONG_FILE_NAME_FORMAT: 'Fehler - Dateinamen-Format ungültig',
      ERROR_INVALID_BANK_ACCOUNT_NUMBER: 'Fehler - Kontonummer ungültig',
      ERROR_WHILE_PROCESSING: 'unerwarteter Verarbeitungsfehler',
      IMPORT_SUCCESSFUL: 'erfolgreich importiert',
    },
  },
};
