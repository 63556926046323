export default {
  title: 'Sachbearbeiter',
  list: {
    header: {
      name: 'Name',
      email: 'E-Mail',
    },
    delete: {
      title: 'Sachbearbeiter löschen',
      message: 'Soll der Benutzer {fullName} wirklich gelöscht werden?',
    },
  },
  form: {
    titleNew: 'Neuer Sachbearbeiter',
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
    businessUnits: {
      label: 'Geschäftsstellen',
    },
    isLocked: {
      label: 'Nutzer ist gesperrt',
    },
    userPermissions: {
      label: 'Berechtigungen',
    },
    userPermissionsPreSet: {
      label: 'Vorauswahl:',
      OptionReadWrite: 'Lesen und Schreiben',
      OptionReadOnly: 'Nur Lesen',
      OptionNone: 'Keine',
    },
    sendActivationEmail: {
      label: 'sende Aktivierungs-Email',
    },
  },
};
