export default {
  title: 'E-Mail-Vorlagen',
  fileAttachmentCollection: {
    title: 'Datei-Anhänge',
  },
  enums: {
    EmailTemplateType: {
      CONTRACT_DOCUMENT: 'Übersetzungsauftrag',
      CONTRACT_ON_SITE: 'Dolmetscherauftrag',
      CONTRACT_VIDEO: 'Videodolmetscherauftrag',
      TRANSLATOR: 'Dolmetscher',
      CUSTOMER: 'Kunde',
      INVOICE: 'Rechnung',
      CREDIT: 'Gutschrift',
    },
    EmailTemplatePlaceholder: {
      ADDRESS_NAME: 'Adressenname',
      ADDRESS: 'Adresse',
      BUSINESS_UNIT_NAME: 'Geschäftsstelle',
      COMMENT: 'Kommentar',
      COMPLETE_UNTIL: 'Datum: Zu erledigen bis',
      CONTRACT_NO: 'Auftragsnummer',
      COST_CENTER_NAME: 'Kostenstelle',
      CREDIT_ACCOUNTING_PERIOD_FROM: 'Gutschrift: Abrechnung von',
      CREDIT_ACCOUNTING_PERIOD_UNTIL: 'Gutschrift: Abrechnung bis',
      CREDIT_BANK_ACCOUNT_HOLDER: 'Gutschrift: Kontoinhaber',
      CREDIT_BANK_NAME: 'Gutschrift: Name der Bank',
      CREDIT_BIC: 'Gutschrift: BIC',
      CREDIT_DATE: 'Gutschrift: Datum der Gutschrift',
      CREDIT_DOCUMENT_VALUE_NET: 'Gutschriftwert Netto',
      CREDIT_IBAN: 'Gutschrift: IBAN',
      CREDIT_NO: 'Gutschriftnummer',
      CREDIT_REFERENCE: 'Gutschrift: Referenz',
      CUSTOMER_EMAIL: 'Kunde: Email',
      CUSTOMER_FIRSTNAME: 'Kunde: Vorname',
      CUSTOMER_GROUP: 'Kunde: Kundengruppe',
      CUSTOMER_LASTNAME: 'Kunde: Nachname',
      CUSTOMER_NAME: 'Kunde: Name vollständig',
      CUSTOMER_TAXID: 'Kunde: SteuerId',
      CUSTOMER_TRAVEL_OPTION: 'Kunde: Reiseoption',
      DATE: 'Datum',
      INVOICE_DATE: 'Rechnungsdatum',
      INVOICE_DOCUMENT_VALUE_GROSS: 'Rechnung: Wert Brutto',
      INVOICE_DOCUMENT_VALUE_NET: 'Rechnung: Wert Netto',
      INVOICE_EXPLANATION: 'Rechnung: Erklärung',
      INVOICE_LANGUAGE: 'Rechnung: Sprache',
      INVOICE_NO: 'Rechnungsnummer',
      INVOICE_NOTICE: 'Rechnung: Notiz',
      INVOICE_OPERATION_LOCATION: 'Rechnung: Einsatzort',
      INVOICE_OPERATION_TIME: 'Rechnung: Einsatzdatum',
      INVOICE_REFERENCE: 'Rechnung: Referenz',
      INVOICE_STATE: 'Rechnungsstatus',
      LINES_OF_ORIGINAL_DOCUMENT: 'Zeilen des originale Dokuments',
      LINES_OF_TRANSLATED_DOCUMENT: 'Zeilen des übersetzten Dokuments',
      PLANNED_TRANSLATION_DURATION: 'Geplante Übersetzungsdauer',
      REAL_TRANSLATION_DURATION: 'Tatsächliche Übersetzungsdauer',
      RECEIVER_CONTACT_CITY: 'Empfänger: Stadt',
      RECEIVER_CONTACT_COMPANY: 'Empfänger: Firma',
      RECEIVER_CONTACT_COUNTRY: 'Empfänger: Land',
      RECEIVER_CONTACT_FIRST_NAME: 'Empfänger: Vorname',
      RECEIVER_CONTACT_LAST_NAME: 'Empfänger: Nachname',
      RECEIVER_CONTACT_STREET: 'Empfänger: Straße',
      RECEIVER_CONTACT_ZIPCODE: 'Empfänger: Postleitzahl',
      SENDER_CONTACT_CITY: 'Absender: Stadt',
      SENDER_CONTACT_COMPANY: 'Absender: Firma',
      SENDER_CONTACT_COUNTRY: 'Absender: Land',
      SENDER_CONTACT_FIRST_NAME: 'Absender: Vorname',
      SENDER_CONTACT_LAST_NAME: 'Absender: Nachname',
      SENDER_CONTACT_STREET: 'Absender: Straße',
      SENDER_CONTACT_ZIPCODE: 'Absender: Postleitzahl',
      TRANSLATOR_ADDRESS_NAME: 'Übersetzer: Name',
      TRANSLATOR_ADDRESS: 'Übersetzer: Adresse',
      TRANSLATOR_BANKACCOUNTHOLDER: 'Übersetzer: Kontoinhaber',
      TRANSLATOR_BANKNAME: 'Übersetzer: Name der Bank',
      TRANSLATOR_BIC: 'Übersetzer: BIC',
      TRANSLATOR_EMAIL: 'Übersetzer: E-Mail',
      TRANSLATOR_FIRSTNAME: 'Übersetzer: Vorname',
      TRANSLATOR_IBAN: 'Übersetzer: IBAN',
      TRANSLATOR_LASTNAME: 'Übersetzer: Nachname',
      TRANSLATOR_NAME: 'Übersetzer: Name vollständig',
      TRANSLATOR_TAXID: 'Übersetzer: SteuerId',
      TRANSLATOR_TRANSLATORCOMMENT: 'Übersetzer: Kommentar',
      TRANSLATOR_TRAVEL_OPTION: 'Übersetzer: Reiseoption',
      TRAVEL_DISTANCE: 'Anreiseweg',
      TRAVEL_TICKET_COST: 'Ticketkosten',
      TRAVEL_TIME_BACK: 'Rückreisezeit',
      TRAVEL_TIME_TO_LOCATION: 'Anreisezeit',
    },
  },
  list: {
    header: {
      name: 'Name',
      emailTemplateType: 'Typ',
      businessUnit: 'Geschäftsstelle',
    },
    delete: {
      title: 'Vorlage löschen',
      message: 'Soll die E-Mail-Vorlage {name} wirklich gelöscht werden?',
    },
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
  form: {
    titleNew: 'Neue E-Mail-Vorlage',
    name: {
      label: 'Name',
    },
    emailTemplateType: {
      label: 'Typ',
    },
    businessUnit: {
      label: 'Geschäftsstelle',
      hint: 'Wählen Sie eine Geschäftsstelle aus, wenn die Vorlage nur für diese gelten soll.',
    },
    subject: {
      label: 'Betreff',
      insertPlaceholder: {
        tooltip: 'Platzhalter einfügen',
      },
    },
    body: {
      label: 'Inhalt',
    },
  },
};
