export default {
  title: 'Externe Belege',
  fileAttachmentCollection: {
    title: 'Datei-Anhänge',
  },
  dialog: {
    showDownloadZipCheck: {
      title: 'Hinweis zum Sammel-Download',
      text: 'Für folgende Belege wurden keine Anhänge gefunden:',
      downloadForced: 'Alle anderen downloaden',
      close: 'Schließen',
    },
  },
  list: {
    header: {
      documentReference: 'Belegnummer',
      documentDate: 'Belegdatum',
      isIncome: 'Einnahmebeleg',
      documentValueNet: 'Belegwert (Netto)',
      documentValueGross: 'Belegwert (Brutto)',
      documentCreator: 'Belegersteller',
      businessUnit: 'Geschäftsstelle',
      documentIsVatFull: 'Mwst - voll',
      documentIsVatReduced: 'Mwst - reduziert',
      documentIsVatZero: 'Mwst - ohne',
      downloadFiles: 'Datei-Anhänge',
    },
    downloadAccumulativeFiles: {
      label: 'Download-Anhänge (ZIP)',
    },
    delete: {
      title: 'Beleg löschen',
      message: 'Soll der Beleg wirklich gelöscht werden?',
    },
    filter: {
      documentReference: {
        label: 'Belegnummer',
      },
      documentCreator: {
        label: 'Belegersteller enthält',
      },
      businessUnit: {
        label: 'Geschäftsstelle ist',
      },
      hasFilesAttached: {
        label: 'hat Datei-Anhänge',
        true: 'Ja',
        false: 'Nein',
      },
      documentDateFrom: {
        label: 'Belegdatum ab',
      },
      documentDateUntil: {
        label: 'Belegdatum bis',
      },
      documentValueFrom: {
        label: 'Belegwert ab',
      },
      documentValueTo: {
        label: 'Belegwert bis',
      },
    },
  },
  form: {
    titleNew: 'Neuer Externer Beleg',
    documentDate: {
      label: 'Belegdatum',
    },
    documentReference: {
      label: 'Belegreferenz',
    },
    isIncome: {
      label: 'Einnahmebeleg',
    },
    documentValueNet: {
      label: 'Belegwert (Netto)',
    },
    documentValueGross: {
      label: 'Belegwert (Brutto)',
    },
    documentCreator: {
      label: 'Belegersteller',
    },
    businessUnit: {
      label: 'Geschäftsstelle',
    },
    isVatFull: {
      label: 'Mwst - voll',
    },
    isVatReduced: {
      label: 'Mwst - reduziert',
    },
    isVatZero: {
      label: 'Mwst - ohne',
    },
    noticeTextIncome: 'Dieser Belege führt zu einer Einahme von {0}.',
    noticeTextSpending: 'Dieser Belege führt zu einer Ausgabe von {0}.',
  },
  fileform: {
    title: 'Datei-Anhänge',
    noData: 'keine Datei-Anhänge',
    filename: 'Dateiname',
    uploadRequest: 'Bitte laden Sie Ihren Anhang hoch.',
    file: 'Datei',
  },
};
